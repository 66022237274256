export const labels = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const createDataset = (data: any): number[] => {
  const expenseData: number[] = labels?.map(monthKey => {
    const monthAmount = data?.reduce((parentAcc: number, parent: any) => {
      const childAmount = parent?.children?.reduce(
        (childAcc: number, child: any) => {
          if (!child?.months?.[monthKey as any]) return childAcc

          const sumOfChildCategory = child?.months?.[monthKey as any]?.reduce(
            (nrAcc: number, number: number) => nrAcc + number,
            0,
          )

          return childAcc + sumOfChildCategory
        },
        0,
      )
      if (!childAmount) return parentAcc

      return parentAcc + childAmount
    }, 0)

    return monthAmount || 0
  })

  return expenseData
}
