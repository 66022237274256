import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useEffect, useState } from 'react'

import { createDataset, labels } from './methods'
import { SummaryProps } from './SummaryLine'

const SummaryAccordion: React.FC<SummaryProps> = ({
  summary,
}: SummaryProps) => {
  const [expenses, setExpenses] = useState<(number | string)[]>([])
  const [transfers, setTransfers] = useState<(number | string)[]>([])
  const [income, setIncome] = useState<(number | string)[]>([])
  const [savings, setSavings] = useState<(number | string)[]>([])
  const headers = ['', ...labels, 'Total', 'Average']

  useEffect(() => {
    if (summary) {
      const _expenses = createDataset(summary?.expenses)
      const expensesTotal = _expenses?.reduce((acc, nr) => acc + nr, 0)
      const expensesAvg = expensesTotal / _expenses?.length

      setExpenses(['Expenses', ..._expenses, expensesTotal, expensesAvg])

      const _transfers = createDataset(summary?.transfers)
      const transferTotal = _transfers?.reduce((acc, nr) => acc + nr, 0)
      const transferAvg = transferTotal / _transfers?.length

      setTransfers(['Transfers', ..._transfers, transferTotal, transferAvg])

      const _income = createDataset(summary?.income)
      const incomeTotal = _income?.reduce((acc, nr) => acc + nr, 0)
      const incomeAvg = incomeTotal / _income?.length

      setIncome(['Income', ..._income, incomeTotal, incomeAvg])

      const _savings = _expenses?.map((monthNr, i) => _income[i] - monthNr)
      const savingsTotal = incomeTotal - expensesTotal
      const savingsAvg = savingsTotal / _savings?.length

      setSavings(['Savings', ..._savings, savingsTotal, savingsAvg])
    }
  }, [summary])

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography style={{ textTransform: 'uppercase' }}>Summary</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {headers?.map((header, i) => (
                  <TableCell align="right" key={i}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {expenses?.map((expense, i) => {
                  if (typeof expense === 'string') {
                    return (
                      <TableCell key={i} align="left">
                        {expense}
                      </TableCell>
                    )
                  }

                  return (
                    <TableCell key={i} align="right">
                      {expense.toFixed(2)} kr
                    </TableCell>
                  )
                })}
              </TableRow>
              <TableRow>
                {income?.map((monthIncome, i) => {
                  if (typeof monthIncome === 'string') {
                    return (
                      <TableCell key={i} align="left">
                        {monthIncome}
                      </TableCell>
                    )
                  }

                  return (
                    <TableCell key={i} align="right">
                      {monthIncome.toFixed(2)} kr
                    </TableCell>
                  )
                })}
              </TableRow>
              <TableRow>
                {transfers?.map((transfer, i) => {
                  if (typeof transfer === 'string') {
                    return (
                      <TableCell key={i} align="left">
                        {transfer}
                      </TableCell>
                    )
                  }

                  return (
                    <TableCell key={i} align="right">
                      {transfer.toFixed(2)} kr
                    </TableCell>
                  )
                })}
              </TableRow>
              <TableRow>
                {savings?.map((saving, i) => {
                  if (typeof saving === 'string') {
                    return (
                      <TableCell key={i} align="left">
                        {saving}
                      </TableCell>
                    )
                  }

                  return (
                    <TableCell key={i} align="right">
                      {saving.toFixed(2)} kr
                    </TableCell>
                  )
                })}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  )
}

export default SummaryAccordion
