import { Typography } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ErrorProps, formatErrors } from '../../../contexts/Auth'
import {
  IsLoggedInDocument,
  IsLoggedInQuery,
  useVerifyMutation,
} from '../../../generated/graphql'

const Verify = (): JSX.Element => {
  const { token } = useParams()

  const navigate = useNavigate()

  const [error, setError] = useState<ErrorProps[]>()

  const [useVerify, { loading, called }] = useVerifyMutation()

  const handleVerification = async (tk: string) => {
    const res = await useVerify({
      variables: {
        token: tk,
      },
      update: (cache, { data }) => {
        if (data?.verify?.user) {
          cache.writeQuery<IsLoggedInQuery>({
            query: IsLoggedInDocument,
            data: {
              __typename: 'Query',
              isLoggedIn: data?.verify?.user,
            },
          })
        }
      },
    })

    if (res.data?.verify?.fieldError) {
      setError(formatErrors(res.data.verify.fieldError))
    } else if (res.data?.verify?.user) {
      navigate('/')
    }
  }

  useEffect(() => {
    if (token) {
      handleVerification(token)
    }
  }, [token])

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}>
      {(loading || !called) && <Typography>Verifying...</Typography>}
      {error &&
        error?.map((err, i) => (
          <Typography key={i} style={{ color: 'red' }}>
            {err.message}
          </Typography>
        ))}
    </div>
  )
}

export default Verify
