import { Card, CardContent } from '@material-ui/core'
import { useEffect, useState } from 'react'

const SavingsBox = ({ data }: { data: any }): JSX.Element => {
  const [percentIncrease, setPercentIncrease] = useState<number>()
  const [nrIncrease, setNumberIncrease] = useState<number>()

  useEffect(() => {
    if (data) {
      const ingoing = data?.reduce(
        (acc: number, asset: any) => asset?.startingBalance + acc,
        0,
      )
      const outgoing = data?.reduce(
        (acc: number, asset: any) =>
          asset?.startingBalance + asset?.month + acc,
        0,
      )
      const percent = Math.round((outgoing / ingoing) * 100) - 100
      const nr = outgoing - ingoing

      setPercentIncrease(percent)
      setNumberIncrease(nr)
    }
  }, [data])

  return (
    <div
      style={{
        width: 'calc(50% - 24px)',
        margin: '0 0 48px 24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Card
        style={{
          width: '100%',
          padding: '32px 80px',
        }}>
        <CardContent>
          <h1 style={{ margin: '0', textAlign: 'center' }}>
            {percentIncrease}%
          </h1>
          <p
            style={{
              margin: '0',
              textAlign: 'center',
              borderBottom: '1px dotted rgba(0, 0, 0, 0.4)',
              padding: '7px 0 15px 0',
            }}>
            Increase of total Savings
          </p>

          <h1
            style={{
              margin: '0',
              textAlign: 'center',
              padding: '15px 0 7px 0',
            }}>
            {nrIncrease?.toFixed(2)} SEK
          </h1>
          <p style={{ margin: '0', textAlign: 'center' }}>
            This months savings
          </p>
        </CardContent>
      </Card>
    </div>
  )
}

export default SavingsBox
