import { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'

const OutInBalance = ({ data }: { data: any }): JSX.Element => {
  const [datasets, setDatasets] = useState<number[]>()

  useEffect(() => {
    if (data) {
      const ingoing = data?.reduce(
        (acc: number, asset: any) => asset?.startingBalance + acc,
        0,
      )
      const outgoing = data?.reduce(
        (acc: number, asset: any) =>
          asset?.startingBalance + asset?.month + acc,
        0,
      )

      setDatasets([ingoing, outgoing])
    }
  }, [data])

  return (
    <div
      style={{
        display: 'flex',
        width: 'calc(50% - 24px)',
        marginBottom: '48px',
        padding: '24px 0',
        marginRight: '24px',
        justifyContent: 'center',
      }}>
      <Bar
        data={{
          labels: ['Ingoing Balance', 'Outgoing Balance'],
          datasets: [
            {
              label: 'SEK',
              xAxisID: 'xOne',
              data: datasets,
              backgroundColor: 'rgba(0, 255, 152, 0.2)',
              borderColor: 'rgba(0, 255, 152, 1)',
              borderWidth: 1,
            },
            {
              label: 'SEK',
              xAxisID: 'xTwo',
              data: datasets,
              hidden: true,
              backgroundColor: 'rgba(0, 255, 152, 0.2)',
              borderColor: 'rgba(0, 255, 152, 1)',
              borderWidth: 1,
            },
          ],
        }}
        options={{
          scales: {
            xOne: {
              ticks: {
                color: '#fff',
                font: {
                  size: 17,
                },
              },
              grid: {
                display: false,
                drawBorder: false,
              },
            },
            xTwo: {
              ticks: {
                color: '#fff',
                font: {
                  size: 17,
                },
                callback: (_: any, index: any) => {
                  const obj = datasets?.[index]

                  return `${obj?.toFixed(2)} SEK`
                },
              },
              grid: {
                display: false,
                drawBorder: false,
              },
            },
            y: {
              ticks: {
                display: false,
              },
              grid: {
                display: false,
                drawBorder: false,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
      />
    </div>
  )
}

export default OutInBalance
