import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { format } from 'date-fns'

export const Datarows = ({
  data,
  type,
}: {
  data: any
  type: 'Income' | 'Transfers' | 'Expenses'
}): JSX.Element => {
  const headers = [
    'Amount',
    'Date',
    'Category',
    'Description',
    'Identifier',
    ...(type !== 'Expenses' ? ['To Account'] : []),
    ...(type !== 'Income' ? ['From Account'] : []),
  ]
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{type}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {headers?.map((header, i) => (
                  <TableCell key={i}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row: any, i: number) => (
                <TableRow key={i}>
                  <TableCell>{row?.amount} kr</TableCell>
                  <TableCell>
                    {format(new Date(row?.transactionDate), 'E	do')}
                  </TableCell>
                  <TableCell>{row?.category?.name}</TableCell>
                  <TableCell>{row?.description}</TableCell>
                  <TableCell>{row?.identifier}</TableCell>
                  {type !== 'Expenses' && (
                    <TableCell>{row?.toAccount?.name}</TableCell>
                  )}
                  {type !== 'Income' && (
                    <TableCell>{row?.fromAccount?.name}</TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  )
}
