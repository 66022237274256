import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'

import {
  TransactionType,
  useAccountsQuery,
  useCategoryParentsQuery,
  useDatarowsQuery,
} from '../../../../../generated/graphql'
import { useAuth } from '../../../../../hooks/useAuth'
import { StepProps } from './../../index.d'
import CategoryChart from './CategoryChart'

interface ModifiedAccount {
  main: boolean
  balance: number
  transferAmount: number
  transferPercent: number
  name: string
}

const ResultStep: React.FC<StepProps> = ({ formValues }: StepProps) => {
  const { user } = useAuth()
  if (!user) return null
  const { rows } = formValues
  const [sortedAccounts, setSortedAccounts] = useState<ModifiedAccount[]>([])

  const { data: { accounts } = {} } = useAccountsQuery({
    variables: {
      userId: user.id,
    },
  })

  const { data: { datarows } = {} } = useDatarowsQuery({
    variables: {
      userId: user.id,
    },
  })

  const totalIncome =
    rows?.reduce((total, row) => {
      if (row?.transactionType === TransactionType.income) {
        total += row?.amount
      }
      return total
    }, 0) || 0

  const totalExpenses =
    rows?.reduce((total, row) => {
      if (row?.transactionType === TransactionType.expense) {
        total += row?.amount
      }
      return total
    }, 0) || 0

  const formatTotalExpenses =
    (totalExpenses && Math.abs(Math.round(totalExpenses * 100) / 100)) || 0

  const totalSaved =
    totalIncome && formatTotalExpenses && totalIncome - formatTotalExpenses

  const adjustedTotalTransfer =
    rows?.reduce((total, row) => {
      if (row?.transactionType === TransactionType.transfer) {
        total += row?.amount * -1
      }

      return total
    }, 0) || 0

  useEffect(() => {
    if (accounts && datarows) {
      const totalMainBalance = datarows?.reduce((acc, row) => {
        const test = rows?.some(
          _row => _row?.transactionDate === row?.transactionDate,
        )
        if (!test && row?.amount) {
          return acc + row?.amount
        }

        return acc
      }, 0)

      const _accounts = accounts
        ?.map(acc => {
          const transferDatarows = rows?.filter(
            row => row?.transactionType === TransactionType.transfer,
          )

          // const addTransfers =
          //   acc?.to?.reduce((acc, transfer) => {
          //     const test = transferDatarows?.some(
          //       row => row?.transactionDate === transfer?.transactionDate,
          //     )
          //     if (test) return acc

          //     return transfer?.amount ? transfer?.amount * -1 + acc : acc
          //   }, 0) || 0

          // const balance = acc?.startingBalance
          //   ? acc?.startingBalance + addTransfers
          //   : addTransfers

          const transferAmount =
            transferDatarows?.reduce(
              (_acc, row) =>
                row?.toAccount === acc?.id ? row?.amount * -1 + _acc : _acc,
              0,
            ) || 0

          if (acc?.main) {
            return {
              name: acc?.name,
              main: acc?.main,
              balance: 0 + totalMainBalance,
              transferAmount: totalSaved - adjustedTotalTransfer,
              // transferPercent:
              //   ((totalSaved - adjustedTotalTransfer) / balance) * 100,
            }
          }

          return {
            name: acc?.name,
            main: acc?.main,
            balance: 0,
            transferAmount,
            transferPercent: (transferAmount / 1) * 100,
          }
        })
        ?.sort((a, b) => (a?.main ? -1 : b?.main ? 1 : 0))
        ?.filter(acc => acc.transferAmount !== 0)
      setSortedAccounts(_accounts as ModifiedAccount[])
    }
  }, [accounts, datarows])

  const { data: { categoryParents } = {} } = useCategoryParentsQuery({
    variables: {
      userId: user.id,
    },
  })

  const totalTransfers =
    rows?.reduce((total, row) => {
      if (row?.transactionType === TransactionType.transfer) {
        total += Math.abs(row?.amount)
      }

      return total
    }, 0) || 0

  const startingBalance =
    accounts?.reduce(
      (acc, account) =>
        account?.startingBalance ? account?.startingBalance + acc : acc,
      0,
    ) || 0

  const outgoingBalance: number = totalSaved && totalSaved + startingBalance

  const percentIncreaseInSavings = Math.round(
    (outgoingBalance / startingBalance - 1) * 100,
  )

  const someBarChartData = [
    {
      name: 'Expenses',
      data: formatTotalExpenses,
    },
    {
      name: 'Transfers',
      data: totalTransfers,
    },
    {
      name: 'Income',
      data: totalIncome,
    },
  ]

  const moreChartData = [startingBalance, outgoingBalance]

  return (
    <div style={{ padding: '48px', display: 'flex', flexWrap: 'wrap' }}>
      <div
        style={{
          display: 'flex',
          width: 'calc(50% - 24px)',
          marginBottom: '48px',
          padding: '24px 0',
          marginRight: '24px',
          justifyContent: 'center',
        }}>
        <Bar
          data={{
            labels: ['Ingoing Balance', 'Outgoing Balance'],
            datasets: [
              {
                label: 'SEK',
                xAxisID: 'xOne',
                data: moreChartData,
                backgroundColor: 'rgba(0, 255, 152, 0.2)',
                borderColor: 'rgba(0, 255, 152, 1)',
                borderWidth: 1,
              },
              {
                label: 'SEK',
                xAxisID: 'xTwo',
                data: moreChartData,
                hidden: true,
                backgroundColor: 'rgba(0, 255, 152, 0.2)',
                borderColor: 'rgba(0, 255, 152, 1)',
                borderWidth: 1,
              },
            ],
          }}
          options={{
            scales: {
              xOne: {
                ticks: {
                  color: '#fff',
                  font: {
                    size: 17,
                  },
                },
                grid: {
                  display: false,
                  drawBorder: false,
                },
              },
              xTwo: {
                ticks: {
                  color: '#fff',
                  font: {
                    size: 17,
                  },
                  callback: (_: any, index: any) => {
                    const obj = moreChartData[index]

                    return `${obj} SEK`
                  },
                },
                grid: {
                  display: false,
                  drawBorder: false,
                },
              },
              y: {
                ticks: {
                  display: false,
                },
                grid: {
                  display: false,
                  drawBorder: false,
                },
              },
            },
            plugins: {
              legend: {
                display: false,
              },
            },
          }}
        />
      </div>

      <div
        style={{
          width: 'calc(50% - 24px)',
          margin: '0 0 48px 24px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Card
          style={{
            width: '100%',
            padding: '32px 80px',
          }}>
          <CardContent>
            <h1 style={{ margin: '0', textAlign: 'center' }}>
              {percentIncreaseInSavings}%
            </h1>
            <p
              style={{
                margin: '0',
                textAlign: 'center',
                borderBottom: '1px dotted rgba(0, 0, 0, 0.4)',
                padding: '7px 0 15px 0',
              }}>
              Increase of total Savings
            </p>

            <h1
              style={{
                margin: '0',
                textAlign: 'center',
                padding: '15px 0 7px 0',
              }}>
              {totalSaved.toFixed(0)} SEK
            </h1>
            <p style={{ margin: '0', textAlign: 'center' }}>
              This months savings
            </p>
          </CardContent>
        </Card>
      </div>

      <div
        style={{
          width: 'calc(50% - 24px)',
          marginRight: '24px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Card
          style={{
            width: '100%',
          }}>
          <CardContent>
            <div style={{ display: 'flex' }}>
              <p
                style={{
                  margin: '0',
                  width: '35%',
                  padding: '5px 10px',
                  borderRight: '1px solid rgba(0, 0, 0, 0.4)',
                  fontWeight: 700,
                  borderBottom: '1px solid rgba(0, 0, 0, 0.4)',
                }}>
                Account Name
              </p>
              <p
                style={{
                  margin: '0',
                  width: '25%',
                  padding: '5px 10px',
                  textAlign: 'right',
                  borderRight: '1px solid rgba(0, 0, 0, 0.4)',
                  fontWeight: 700,
                  borderBottom: '1px solid rgba(0, 0, 0, 0.4)',
                }}>
                Balance
              </p>
              <p
                style={{
                  margin: '0',
                  width: '25%',
                  padding: '5px 10px',
                  textAlign: 'right',
                  borderRight: '1px solid rgba(0, 0, 0, 0.4)',
                  fontWeight: 700,
                  borderBottom: '1px solid rgba(0, 0, 0, 0.4)',
                }}>
                + / - in SEK
              </p>
              <p
                style={{
                  margin: '0',
                  width: '15%',
                  padding: '5px 10px',
                  textAlign: 'right',
                  borderBottom: '1px solid rgba(0, 0, 0, 0.4)',
                  fontWeight: 700,
                }}>
                + / - %
              </p>
            </div>
            {sortedAccounts?.map((account, i, arr) => {
              const borderBottom =
                arr?.length - 1 === i ? 'none' : '1px solid rgba(0, 0, 0, 0.4)'
              return (
                <div key={i} style={{ display: 'flex' }}>
                  <p
                    style={{
                      margin: '0',
                      width: '35%',
                      padding: '5px 10px',
                      borderRight: '1px solid rgba(0, 0, 0, 0.4)',
                      borderBottom,
                    }}>
                    {account?.name}
                  </p>
                  <p
                    style={{
                      margin: '0',
                      width: '25%',
                      padding: '5px 10px',
                      textAlign: 'right',
                      borderRight: '1px solid rgba(0, 0, 0, 0.4)',
                      borderBottom,
                    }}>
                    {account?.balance} SEK
                  </p>
                  <p
                    style={{
                      margin: '0',
                      width: '25%',
                      padding: '5px 10px',
                      textAlign: 'right',
                      borderRight: '1px solid rgba(0, 0, 0, 0.4)',
                      borderBottom,
                      color:
                        account?.transferAmount > 0
                          ? '#b6d7a7'
                          : account?.transferAmount < 0
                          ? '#ea9999'
                          : '#fff',
                    }}>
                    {account?.transferAmount.toFixed(0)} SEK
                  </p>
                  <p
                    style={{
                      margin: '0',
                      width: '15%',
                      padding: '5px 10px',
                      textAlign: 'right',
                      borderBottom,
                      color:
                        account?.transferPercent > 0
                          ? '#b6d7a7'
                          : account?.transferPercent < 0
                          ? '#ea9999'
                          : '#fff',
                    }}>
                    {account?.transferPercent >= 1 ||
                    account?.transferPercent <= -1 ||
                    account?.transferPercent === 0
                      ? `${account?.transferPercent.toFixed(0)}%`
                      : `${account?.transferPercent.toFixed(1)}%`}
                  </p>
                </div>
              )
            })}
          </CardContent>
        </Card>
      </div>

      <div style={{ width: 'calc(50% - 24px)', marginLeft: '24px' }}>
        <Bar
          data={{
            labels: someBarChartData?.map(type => type?.name),
            datasets: [
              {
                label: 'SEK',
                yAxisID: 'yOne',
                data: someBarChartData?.map(type => type?.data),
                backgroundColor: 'rgba(0, 255, 152, 0.2)',
                borderColor: 'rgba(0, 255, 152, 1)',
                borderWidth: 1,
              },
              {
                label: 'SEK',
                yAxisID: 'yTwo',
                data: someBarChartData?.map(type => type?.data),
                hidden: true,
                backgroundColor: 'rgba(0, 255, 152, 0.2)',
                borderColor: 'rgba(0, 255, 152, 1)',
                borderWidth: 1,
              },
            ],
          }}
          options={{
            indexAxis: 'y',
            scales: {
              x: {
                ticks: {
                  display: false,
                },
                grid: {
                  display: false,
                  drawBorder: false,
                },
              },
              yTwo: {
                ticks: {
                  color: '#fff',

                  font: {
                    size: 14,
                  },
                  callback: (_: any, index: any) => {
                    const obj = someBarChartData[index]

                    return `${obj?.data} SEK`
                  },
                },
                grid: {
                  display: false,
                  drawBorder: false,
                },
              },
              yOne: {
                ticks: {
                  color: '#fff',
                  crossAlign: 'far',
                  font: {
                    size: 14,
                  },
                },
                grid: {
                  display: false,
                  drawBorder: false,
                },
              },
            },
            plugins: {
              legend: {
                display: false,
              },
            },
          }}
        />
      </div>
      <CategoryChart
        datarows={datarows}
        rows={rows}
        categoryParents={categoryParents}
      />

      <div style={{ width: '100%' }}>
        {Object.keys(TransactionType)?.map(type => {
          const width = type === TransactionType.transfer ? '16.66%' : '20%'
          return (
            <Accordion key={type}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={type}
                id={type}>
                <Typography style={{ textTransform: 'uppercase' }}>
                  {type}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ width: '100%' }}>
                  <div style={{ display: 'flex', width: '100%' }}>
                    <div
                      style={{
                        width,
                        borderBottom: '1px solid rgba(0, 0, 0, 0.4)',
                        borderRight: '1px solid rgba(0, 0, 0, 0.4)',
                        padding: '5px 10px',
                        fontWeight: 700,
                      }}>
                      Amount
                    </div>
                    <div
                      style={{
                        width,
                        borderBottom: '1px solid rgba(0, 0, 0, 0.4)',
                        borderRight: '1px solid rgba(0, 0, 0, 0.4)',
                        padding: '5px 10px',
                        fontWeight: 700,
                      }}>
                      Date
                    </div>
                    <div
                      style={{
                        width,
                        borderBottom: '1px solid rgba(0, 0, 0, 0.4)',
                        borderRight: '1px solid rgba(0, 0, 0, 0.4)',
                        padding: '5px 10px',
                        fontWeight: 700,
                      }}>
                      Category
                    </div>
                    <div
                      style={{
                        width,
                        borderBottom: '1px solid rgba(0, 0, 0, 0.4)',
                        borderRight: '1px solid rgba(0, 0, 0, 0.4)',
                        padding: '5px 10px',
                        fontWeight: 700,
                      }}>
                      Description
                    </div>
                    <div
                      style={{
                        width,
                        borderBottom: '1px solid rgba(0, 0, 0, 0.4)',
                        padding: '5px 10px',
                        borderRight:
                          type === TransactionType.transfer
                            ? '1px solid rgba(0, 0, 0, 0.4)'
                            : 'none',
                        fontWeight: 700,
                      }}>
                      Identifier
                    </div>
                    {type === TransactionType.transfer && (
                      <div
                        style={{
                          width,
                          borderBottom: '1px solid rgba(0, 0, 0, 0.4)',
                          padding: '5px 10px',
                          fontWeight: 700,
                        }}>
                        Account
                      </div>
                    )}
                  </div>
                  {rows
                    ?.filter(row => row?.transactionType === type)
                    ?.map((row, i, arr) => {
                      const borderBottom =
                        arr?.length - 1 === i
                          ? 'none'
                          : '1px solid rgba(0, 0, 0, 0.4)'
                      return (
                        <div key={i} style={{ display: 'flex', width: '100%' }}>
                          <div
                            style={{
                              width,
                              borderBottom,
                              borderRight: '1px solid rgba(0, 0, 0, 0.4)',
                              padding: '5px 10px',
                            }}>
                            {row?.amount}
                          </div>
                          <div
                            style={{
                              width,
                              borderBottom,
                              borderRight: '1px solid rgba(0, 0, 0, 0.4)',
                              padding: '5px 10px',
                            }}>
                            {format(new Date(row?.transactionDate), 'E	do')}
                          </div>
                          <div
                            style={{
                              width,
                              borderBottom,
                              borderRight: '1px solid rgba(0, 0, 0, 0.4)',
                              padding: '5px 10px',
                            }}>
                            {categoryParents?.reduce((acc, parent) => {
                              const category = parent?.children?.find(
                                child => child?.id === row?.category,
                              )
                              if (category) return category?.name ?? acc

                              return acc
                            }, '')}
                          </div>
                          <div
                            style={{
                              width,
                              borderBottom,
                              borderRight: '1px solid rgba(0, 0, 0, 0.4)',
                              padding: '5px 10px',
                            }}>
                            {row?.description}
                          </div>
                          <div
                            style={{
                              width,
                              borderBottom,
                              padding: '5px 10px',
                              borderRight:
                                type === TransactionType.transfer
                                  ? '1px solid rgba(0, 0, 0, 0.4)'
                                  : 'none',
                            }}>
                            {row?.identifier}
                          </div>
                          {type === TransactionType.transfer && (
                            <div
                              style={{
                                width,
                                borderBottom,
                                padding: '5px 10px',
                              }}>
                              {
                                accounts?.find(
                                  account => account?.id === row?.toAccount,
                                )?.name
                              }
                            </div>
                          )}
                        </div>
                      )
                    })}
                </div>
              </AccordionDetails>
            </Accordion>
          )
        })}
      </div>
    </div>
  )
}

export default ResultStep
