import AccountBoxIcon from '@material-ui/icons/AccountBox'
import AccountTreeIcon from '@material-ui/icons/AccountTree'
import HomeIcon from '@material-ui/icons/Home'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import TableChartIcon from '@material-ui/icons/TableChart'

import Accounts from '../components/pages/Accounts'
import CategoryBreakdown from '../components/pages/CategoryBreakdown'
import ChangePassword from '../components/pages/ChangePassword'
import ForgotPassword from '../components/pages/ForgotPassword'
import Home from '../components/pages/Home'
import Importer from '../components/pages/importer'
import Login from '../components/pages/Login'
import MonthSummary from '../components/pages/MonthSummary'
import NewSummary from '../components/pages/newSummary'
import Register from '../components/pages/Register'
import Verify from '../components/pages/Verify'

export const ProtectedMenu = [
  { icon: <HomeIcon />, text: 'Home', to: '/', element: <Home /> },
  {
    icon: <ImportExportIcon />,
    text: 'Importer',
    to: '/importer',
    element: <Importer />,
  },
  {
    icon: <ShowChartIcon />,
    text: 'Year Summary',
    to: '/summary',
    element: <NewSummary />,
  },
  {
    icon: <ShowChartIcon />,
    text: 'Month Summary',
    to: '/month-summary',
    element: <MonthSummary />,
  },
  {
    icon: <TableChartIcon />,
    text: 'Expenses By Category',
    to: '/expenses',
    element: <CategoryBreakdown type="expenses" />,
  },
  {
    icon: <TableChartIcon />,
    text: 'Transfers By Category',
    to: '/transfers',
    element: <CategoryBreakdown type="transfers" />,
  },
  {
    icon: <TableChartIcon />,
    text: 'Income By Category',
    to: '/income',
    element: <CategoryBreakdown type="income" />,
  },
  {
    icon: <AccountTreeIcon />,
    text: 'Accounts',
    to: '/accounts',
    element: <Accounts />,
  },
  {
    // icon: <TableChartIcon />,
    text: 'Verify',
    to: '/verify/:token',
    element: <Verify />,
    hideInMenu: true,
  },
]

export const PublicMenu = [
  {
    icon: <AccountBoxIcon />,
    text: 'Register',
    to: '/register',
    element: <Register />,
  },
  {
    icon: <AccountBoxIcon />,
    text: 'Login',
    to: '/login',
    element: <Login />,
  },
  {
    // icon: <AccountBoxIcon />,
    text: 'Forgot Password',
    to: '/forgot-password',
    element: <ForgotPassword />,
  },
  {
    // icon: <AccountBoxIcon />,
    text: 'Change Password',
    to: '/change-password/:token',
    element: <ChangePassword />,
  },
]
