import { Switch } from '@material-ui/core'
import { ControllerRenderProps } from 'react-hook-form'

const Boolean: React.FC<ControllerRenderProps> = ({
  value,
  onChange,
}: ControllerRenderProps) => {
  const extendOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked)
  }

  return <Switch checked={value} onChange={extendOnChange} />
}

export default Boolean
