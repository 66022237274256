import {
  Button,
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form'

import {
  useAccountsQuery,
  useCreateAccountMutation,
} from '../../../generated/graphql'
import { useAuth } from '../../../hooks/useAuth'
import Boolean from '../../Inputs/Boolean'

const Accounts = (): JSX.Element | null => {
  const { user } = useAuth()
  if (!user) return null

  const { handleSubmit, control, register, reset } = useForm()

  const { data: { accounts } = {}, refetch } = useAccountsQuery({
    variables: {
      userId: user.id,
    },
  })

  const headers = ['Name', 'Starting Balance', 'Main']

  const [createAccount, { loading, error }] = useCreateAccountMutation()

  const onSubmit = async (values: {
    name: string
    main: boolean
    startingBalance: string
  }) => {
    try {
      const res = await createAccount({
        variables: {
          userId: user.id,
          name: values.name,
          main: values.main,
          startingBalance: Number(values.startingBalance),
        },
      })

      if (res?.data?.createAccount) {
        reset()
        await refetch()
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div style={{ padding: '40px' }}>
      <div style={{ width: '400px' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            name="name"
            placeholder="Name"
            inputRef={register}
            fullWidth
            style={{ marginBottom: '20px' }}
          />
          <TextField
            name="startingBalance"
            type="number"
            placeholder="Starting Balance"
            inputRef={register}
            fullWidth
            style={{ marginBottom: '20px' }}
          />
          <div>
            Is this your main account, for daily use?
            <Controller
              name="main"
              defaultValue={false}
              control={control}
              render={(props: ControllerRenderProps) => <Boolean {...props} />}
            />
          </div>
          {error && (
            <Typography style={{ color: 'red' }}>
              {error.message} - You have to verify your account OR you have
              already marked one account as Main OR there is another account
              with the same name
            </Typography>
          )}
          <Button
            disabled={loading}
            variant="outlined"
            color="primary"
            type="submit">
            Create Account
          </Button>
        </form>
      </div>
      <div>
        <Card
          style={{
            marginTop: '20px',
            width: '50%',
          }}>
          <CardContent>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {headers?.map((header, i) => (
                      <TableCell key={i} align={i === 0 ? 'left' : 'right'}>
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {accounts?.map((row, i: number) => {
                    return (
                      <TableRow key={i}>
                        <TableCell>{row?.name}</TableCell>
                        <TableCell align="right">
                          {row?.startingBalance} kr
                        </TableCell>
                        <TableCell align="right">
                          {JSON.stringify(row?.main)}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

export default Accounts
