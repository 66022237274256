import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'

import { labels } from '../newSummary/methods'
import MultiLevelRow from './MultiLevelRow'

const MultiLevelTable: React.FC<{
  data: any
  type: 'expenses' | 'transfers' | 'income'
}> = ({
  data,
  type,
}: {
  data: any
  type: 'expenses' | 'transfers' | 'income'
}) => {
  const headers = ['', type, ...labels, 'Total', 'Average']
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {headers?.map((header, i) => (
              <TableCell key={i}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((parentRow: any, i: number) => (
            <MultiLevelRow key={i} row={parentRow} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default MultiLevelTable
