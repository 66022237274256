import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any
}

export type Account = {
  __typename?: 'Account'
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  startingBalance?: Maybe<Scalars['Float']>
  main?: Maybe<Scalars['Boolean']>
  to?: Maybe<Array<Maybe<Datarow>>>
  from?: Maybe<Array<Maybe<Datarow>>>
  user?: Maybe<User>
}

export type AssetMonthResponse = {
  __typename?: 'AssetMonthResponse'
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  startingBalance?: Maybe<Scalars['Float']>
  main?: Maybe<Scalars['Boolean']>
  month?: Maybe<Scalars['Float']>
}

export type AssetMonthsResponse = {
  __typename?: 'AssetMonthsResponse'
  Jan?: Maybe<Scalars['Float']>
  Feb?: Maybe<Scalars['Float']>
  Mar?: Maybe<Scalars['Float']>
  Apr?: Maybe<Scalars['Float']>
  May?: Maybe<Scalars['Float']>
  Jun?: Maybe<Scalars['Float']>
  Jul?: Maybe<Scalars['Float']>
  Aug?: Maybe<Scalars['Float']>
  Sep?: Maybe<Scalars['Float']>
  Oct?: Maybe<Scalars['Float']>
  Nov?: Maybe<Scalars['Float']>
  Dec?: Maybe<Scalars['Float']>
}

export type AssetResponse = {
  __typename?: 'AssetResponse'
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  startingBalance?: Maybe<Scalars['Float']>
  main?: Maybe<Scalars['Boolean']>
  months?: Maybe<AssetMonthsResponse>
}

export type Category = {
  __typename?: 'Category'
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  parent?: Maybe<Category>
  children?: Maybe<Array<Maybe<Category>>>
  user?: Maybe<User>
  updatedDate?: Maybe<Scalars['DateTime']>
  createdDate?: Maybe<Scalars['DateTime']>
}

export type CategoryMonthsResponse = {
  __typename?: 'CategoryMonthsResponse'
  Jan?: Maybe<Array<Maybe<Scalars['Float']>>>
  Feb?: Maybe<Array<Maybe<Scalars['Float']>>>
  Mar?: Maybe<Array<Maybe<Scalars['Float']>>>
  Apr?: Maybe<Array<Maybe<Scalars['Float']>>>
  May?: Maybe<Array<Maybe<Scalars['Float']>>>
  Jun?: Maybe<Array<Maybe<Scalars['Float']>>>
  Jul?: Maybe<Array<Maybe<Scalars['Float']>>>
  Aug?: Maybe<Array<Maybe<Scalars['Float']>>>
  Sep?: Maybe<Array<Maybe<Scalars['Float']>>>
  Oct?: Maybe<Array<Maybe<Scalars['Float']>>>
  Nov?: Maybe<Array<Maybe<Scalars['Float']>>>
  Dec?: Maybe<Array<Maybe<Scalars['Float']>>>
}

export type ChildCategoryExpense = {
  __typename?: 'ChildCategoryExpense'
  categoryName?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
  months?: Maybe<CategoryMonthsResponse>
}

export type ChildCategoryIncome = {
  __typename?: 'ChildCategoryIncome'
  categoryName?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
  months?: Maybe<CategoryMonthsResponse>
}

export type ChildCategoryMonthExpense = {
  __typename?: 'ChildCategoryMonthExpense'
  categoryName?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
  month?: Maybe<Array<Maybe<Scalars['Float']>>>
}

export type ChildCategoryMonthIncome = {
  __typename?: 'ChildCategoryMonthIncome'
  categoryName?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
  month?: Maybe<Array<Maybe<Scalars['Float']>>>
}

export type ChildCategoryMonthTransfer = {
  __typename?: 'ChildCategoryMonthTransfer'
  categoryName?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
  month?: Maybe<Array<Maybe<Scalars['Float']>>>
}

export type ChildCategoryTransfer = {
  __typename?: 'ChildCategoryTransfer'
  categoryName?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
  months?: Maybe<CategoryMonthsResponse>
}

export enum ColumnTypes {
  amount = 'amount',
  transactionDate = 'transactionDate',
  identifier = 'identifier',
  description = 'description',
  category = 'category',
  transactionType = 'transactionType',
  toAccount = 'toAccount',
  fromAccount = 'fromAccount',
  isUnusual = 'isUnusual',
}

export type Datamap = {
  __typename?: 'Datamap'
  id?: Maybe<Scalars['String']>
  originKey?: Maybe<Scalars['String']>
  user?: Maybe<User>
  newKey?: Maybe<ColumnTypes>
  updatedDate?: Maybe<Scalars['DateTime']>
  createdDate?: Maybe<Scalars['DateTime']>
}

export type DatamapsCreateInput = {
  originKey: Scalars['String']
  newKey: ColumnTypes
}

export type Datarow = {
  __typename?: 'Datarow'
  id?: Maybe<Scalars['String']>
  amount?: Maybe<Scalars['Float']>
  transactionDate?: Maybe<Scalars['DateTime']>
  identifier?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  isUnusual?: Maybe<Scalars['Boolean']>
  transactionType?: Maybe<TransactionType>
  toAccount?: Maybe<Account>
  fromAccount?: Maybe<Account>
  category?: Maybe<Category>
  user?: Maybe<User>
  updatedDate?: Maybe<Scalars['DateTime']>
  createdDate?: Maybe<Scalars['DateTime']>
}

export type DatarowsCreateInput = {
  amount: Scalars['Float']
  transactionDate?: Maybe<Scalars['DateTime']>
  identifier: Scalars['String']
  description?: Maybe<Scalars['String']>
  transactionType: TransactionType
  category: Scalars['String']
  toAccount?: Maybe<Scalars['String']>
  fromAccount?: Maybe<Scalars['String']>
  isUnusual?: Scalars['Boolean']
}

export type FieldError = {
  __typename?: 'FieldError'
  field: Scalars['String']
  message: Scalars['String']
}

export type MonthResponse = {
  __typename?: 'MonthResponse'
  assets?: Maybe<Array<Maybe<AssetMonthResponse>>>
  expenses?: Maybe<Array<Maybe<ParentCategoryMonthExpense>>>
  transfers?: Maybe<Array<Maybe<ParentCategoryMonthTransfer>>>
  income?: Maybe<Array<Maybe<ParentCategoryMonthIncome>>>
  datarows?: Maybe<Array<Maybe<Datarow>>>
}

export type Mutation = {
  __typename?: 'Mutation'
  createUser?: Maybe<UserResponse>
  login?: Maybe<UserResponse>
  logout?: Maybe<Scalars['Boolean']>
  forgotPassword?: Maybe<Scalars['Boolean']>
  changePassword?: Maybe<UserResponse>
  verify?: Maybe<UserResponse>
  createDatamaps?: Maybe<Array<Maybe<Datamap>>>
  createDatarows?: Maybe<Array<Maybe<Datarow>>>
  createAccount?: Maybe<Account>
}

export type MutationCreateUserArgs = {
  email: Scalars['String']
  password: Scalars['String']
}

export type MutationLoginArgs = {
  email: Scalars['String']
  password: Scalars['String']
}

export type MutationForgotPasswordArgs = {
  email: Scalars['String']
}

export type MutationChangePasswordArgs = {
  token: Scalars['String']
  newPassword: Scalars['String']
}

export type MutationVerifyArgs = {
  token: Scalars['String']
}

export type MutationCreateDatamapsArgs = {
  userId?: Maybe<Scalars['String']>
  data: Array<DatamapsCreateInput>
}

export type MutationCreateDatarowsArgs = {
  userId?: Maybe<Scalars['String']>
  data: Array<DatarowsCreateInput>
}

export type MutationCreateAccountArgs = {
  userId: Scalars['String']
  startingBalance: Scalars['Int']
  name: Scalars['String']
  main?: Maybe<Scalars['Boolean']>
}

export type ParentCategoryExpense = {
  __typename?: 'ParentCategoryExpense'
  categoryName?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
  children?: Maybe<Array<Maybe<ChildCategoryExpense>>>
}

export type ParentCategoryIncome = {
  __typename?: 'ParentCategoryIncome'
  categoryName?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
  children?: Maybe<Array<Maybe<ChildCategoryIncome>>>
}

export type ParentCategoryMonthExpense = {
  __typename?: 'ParentCategoryMonthExpense'
  categoryName?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
  children?: Maybe<Array<Maybe<ChildCategoryMonthExpense>>>
}

export type ParentCategoryMonthIncome = {
  __typename?: 'ParentCategoryMonthIncome'
  categoryName?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
  children?: Maybe<Array<Maybe<ChildCategoryMonthIncome>>>
}

export type ParentCategoryMonthTransfer = {
  __typename?: 'ParentCategoryMonthTransfer'
  categoryName?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
  children?: Maybe<Array<Maybe<ChildCategoryMonthTransfer>>>
}

export type ParentCategoryTransfer = {
  __typename?: 'ParentCategoryTransfer'
  categoryName?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
  children?: Maybe<Array<Maybe<ChildCategoryTransfer>>>
}

export type Query = {
  __typename?: 'Query'
  users?: Maybe<Array<Maybe<User>>>
  isLoggedIn?: Maybe<User>
  datamaps?: Maybe<Array<Maybe<Datamap>>>
  datarows?: Maybe<Array<Maybe<Datarow>>>
  categories?: Maybe<Array<Maybe<Category>>>
  categoryParents?: Maybe<Array<Maybe<Category>>>
  accounts?: Maybe<Array<Maybe<Account>>>
  summary?: Maybe<SummaryResponse>
  monthSummary?: Maybe<MonthResponse>
}

export type QueryDatamapsArgs = {
  userId: Scalars['String']
}

export type QueryDatarowsArgs = {
  userId: Scalars['String']
}

export type QueryCategoriesArgs = {
  userId: Scalars['String']
}

export type QueryCategoryParentsArgs = {
  userId: Scalars['String']
}

export type QueryAccountsArgs = {
  userId: Scalars['String']
}

export type QuerySummaryArgs = {
  year: Scalars['Date']
  userId: Scalars['String']
  includeUnusual?: Scalars['Boolean']
}

export type QueryMonthSummaryArgs = {
  year: Scalars['Date']
  userId: Scalars['String']
  includeUnusual?: Scalars['Boolean']
}

export type SummaryResponse = {
  __typename?: 'SummaryResponse'
  assets?: Maybe<Array<Maybe<AssetResponse>>>
  expenses?: Maybe<Array<Maybe<ParentCategoryExpense>>>
  transfers?: Maybe<Array<Maybe<ParentCategoryTransfer>>>
  income?: Maybe<Array<Maybe<ParentCategoryIncome>>>
}

export enum TransactionType {
  transfer = 'transfer',
  income = 'income',
  expense = 'expense',
}

export type User = {
  __typename?: 'User'
  id: Scalars['String']
  email?: Maybe<Scalars['String']>
  updatedDate?: Maybe<Scalars['DateTime']>
  createdDate?: Maybe<Scalars['DateTime']>
  verified?: Maybe<Scalars['Boolean']>
}

export type UserResponse = {
  __typename?: 'UserResponse'
  fieldError?: Maybe<Array<FieldError>>
  user?: Maybe<User>
}

export type AccountFragmentFragment = { __typename?: 'Account' } & Pick<
  Account,
  'id' | 'name' | 'startingBalance' | 'main'
> & { user?: Maybe<{ __typename?: 'User' } & UserFragmentFragment> }

export type CategoryFragmentFragment = { __typename?: 'Category' } & Pick<
  Category,
  'id' | 'name' | 'updatedDate' | 'createdDate'
> & {
    parent?: Maybe<{ __typename?: 'Category' } & Pick<Category, 'id'>>
    user?: Maybe<{ __typename?: 'User' } & UserFragmentFragment>
  }

export type DatamapFragmentFragment = { __typename?: 'Datamap' } & Pick<
  Datamap,
  'id' | 'originKey' | 'newKey' | 'updatedDate' | 'createdDate'
> & { user?: Maybe<{ __typename?: 'User' } & UserFragmentFragment> }

export type DatarowFragmentFragment = { __typename?: 'Datarow' } & Pick<
  Datarow,
  | 'id'
  | 'amount'
  | 'transactionDate'
  | 'identifier'
  | 'description'
  | 'isUnusual'
  | 'transactionType'
  | 'updatedDate'
  | 'createdDate'
> & {
    toAccount?: Maybe<{ __typename?: 'Account' } & Pick<Account, 'id' | 'main'>>
    fromAccount?: Maybe<
      { __typename?: 'Account' } & Pick<Account, 'id' | 'main'>
    >
    user?: Maybe<{ __typename?: 'User' } & Pick<User, 'id'>>
  }

export type UserFragmentFragment = { __typename?: 'User' } & Pick<
  User,
  'id' | 'email' | 'updatedDate' | 'createdDate' | 'verified'
>

export type ChangePasswordMutationVariables = Exact<{
  token: Scalars['String']
  newPassword: Scalars['String']
}>

export type ChangePasswordMutation = { __typename?: 'Mutation' } & {
  changePassword?: Maybe<
    { __typename?: 'UserResponse' } & {
      fieldError?: Maybe<
        Array<
          { __typename?: 'FieldError' } & Pick<FieldError, 'field' | 'message'>
        >
      >
      user?: Maybe<{ __typename?: 'User' } & UserFragmentFragment>
    }
  >
}

export type CreateAccountMutationVariables = Exact<{
  userId: Scalars['String']
  startingBalance: Scalars['Int']
  name: Scalars['String']
  main: Scalars['Boolean']
}>

export type CreateAccountMutation = { __typename?: 'Mutation' } & {
  createAccount?: Maybe<{ __typename?: 'Account' } & AccountFragmentFragment>
}

export type CreateDatamapsMutationVariables = Exact<{
  userId?: Maybe<Scalars['String']>
  data: Array<DatamapsCreateInput> | DatamapsCreateInput
}>

export type CreateDatamapsMutation = { __typename?: 'Mutation' } & {
  createDatamaps?: Maybe<
    Array<Maybe<{ __typename?: 'Datamap' } & DatamapFragmentFragment>>
  >
}

export type CreateDatarowsMutationVariables = Exact<{
  userId?: Maybe<Scalars['String']>
  data: Array<DatarowsCreateInput> | DatarowsCreateInput
}>

export type CreateDatarowsMutation = { __typename?: 'Mutation' } & {
  createDatarows?: Maybe<
    Array<Maybe<{ __typename?: 'Datarow' } & DatarowFragmentFragment>>
  >
}

export type CreateUserMutationVariables = Exact<{
  email: Scalars['String']
  password: Scalars['String']
}>

export type CreateUserMutation = { __typename?: 'Mutation' } & {
  createUser?: Maybe<
    { __typename?: 'UserResponse' } & {
      fieldError?: Maybe<
        Array<
          { __typename?: 'FieldError' } & Pick<FieldError, 'field' | 'message'>
        >
      >
      user?: Maybe<{ __typename?: 'User' } & UserFragmentFragment>
    }
  >
}

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String']
}>

export type ForgotPasswordMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'forgotPassword'
>

export type LoginMutationVariables = Exact<{
  email: Scalars['String']
  password: Scalars['String']
}>

export type LoginMutation = { __typename?: 'Mutation' } & {
  login?: Maybe<
    { __typename?: 'UserResponse' } & {
      fieldError?: Maybe<
        Array<
          { __typename?: 'FieldError' } & Pick<FieldError, 'field' | 'message'>
        >
      >
      user?: Maybe<{ __typename?: 'User' } & UserFragmentFragment>
    }
  >
}

export type LogoutMutationVariables = Exact<{ [key: string]: never }>

export type LogoutMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'logout'
>

export type VerifyMutationVariables = Exact<{
  token: Scalars['String']
}>

export type VerifyMutation = { __typename?: 'Mutation' } & {
  verify?: Maybe<
    { __typename?: 'UserResponse' } & {
      fieldError?: Maybe<
        Array<
          { __typename?: 'FieldError' } & Pick<FieldError, 'field' | 'message'>
        >
      >
      user?: Maybe<{ __typename?: 'User' } & UserFragmentFragment>
    }
  >
}

export type AccountsQueryVariables = Exact<{
  userId: Scalars['String']
}>

export type AccountsQuery = { __typename?: 'Query' } & {
  accounts?: Maybe<
    Array<Maybe<{ __typename?: 'Account' } & AccountFragmentFragment>>
  >
}

export type CategoriesQueryVariables = Exact<{
  userId: Scalars['String']
}>

export type CategoriesQuery = { __typename?: 'Query' } & {
  categories?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Category' } & {
          children?: Maybe<
            Array<Maybe<{ __typename?: 'Category' } & CategoryFragmentFragment>>
          >
        } & CategoryFragmentFragment
      >
    >
  >
}

export type CategoryParentsQueryVariables = Exact<{
  userId: Scalars['String']
}>

export type CategoryParentsQuery = { __typename?: 'Query' } & {
  categoryParents?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Category' } & {
          children?: Maybe<
            Array<Maybe<{ __typename?: 'Category' } & CategoryFragmentFragment>>
          >
        } & CategoryFragmentFragment
      >
    >
  >
}

export type DatamapsQueryVariables = Exact<{
  userId: Scalars['String']
}>

export type DatamapsQuery = { __typename?: 'Query' } & {
  datamaps?: Maybe<
    Array<Maybe<{ __typename?: 'Datamap' } & DatamapFragmentFragment>>
  >
}

export type DatarowsQueryVariables = Exact<{
  userId: Scalars['String']
}>

export type DatarowsQuery = { __typename?: 'Query' } & {
  datarows?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Datarow' } & {
          category?: Maybe<{ __typename?: 'Category' } & Pick<Category, 'id'>>
        } & DatarowFragmentFragment
      >
    >
  >
}

export type IsLoggedInQueryVariables = Exact<{ [key: string]: never }>

export type IsLoggedInQuery = { __typename?: 'Query' } & {
  isLoggedIn?: Maybe<{ __typename?: 'User' } & UserFragmentFragment>
}

export type MonthSummaryQueryVariables = Exact<{
  userId: Scalars['String']
  year: Scalars['Date']
  includeUnusual: Scalars['Boolean']
}>

export type MonthSummaryQuery = { __typename?: 'Query' } & {
  monthSummary?: Maybe<
    { __typename?: 'MonthResponse' } & {
      assets?: Maybe<
        Array<
          Maybe<
            { __typename?: 'AssetMonthResponse' } & Pick<
              AssetMonthResponse,
              'id' | 'name' | 'startingBalance' | 'main' | 'month'
            >
          >
        >
      >
      expenses?: Maybe<
        Array<
          Maybe<
            { __typename?: 'ParentCategoryMonthExpense' } & Pick<
              ParentCategoryMonthExpense,
              'key' | 'categoryName' | 'id'
            > & {
                children?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'ChildCategoryMonthExpense' } & Pick<
                        ChildCategoryMonthExpense,
                        'key' | 'categoryName' | 'id' | 'month'
                      >
                    >
                  >
                >
              }
          >
        >
      >
      transfers?: Maybe<
        Array<
          Maybe<
            { __typename?: 'ParentCategoryMonthTransfer' } & Pick<
              ParentCategoryMonthTransfer,
              'key' | 'categoryName' | 'id'
            > & {
                children?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'ChildCategoryMonthTransfer' } & Pick<
                        ChildCategoryMonthTransfer,
                        'key' | 'categoryName' | 'id' | 'month'
                      >
                    >
                  >
                >
              }
          >
        >
      >
      income?: Maybe<
        Array<
          Maybe<
            { __typename?: 'ParentCategoryMonthIncome' } & Pick<
              ParentCategoryMonthIncome,
              'key' | 'categoryName' | 'id'
            > & {
                children?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'ChildCategoryMonthIncome' } & Pick<
                        ChildCategoryMonthIncome,
                        'key' | 'categoryName' | 'id' | 'month'
                      >
                    >
                  >
                >
              }
          >
        >
      >
      datarows?: Maybe<
        Array<
          Maybe<
            { __typename?: 'Datarow' } & Pick<
              Datarow,
              | 'id'
              | 'amount'
              | 'transactionDate'
              | 'identifier'
              | 'description'
              | 'isUnusual'
              | 'transactionType'
            > & {
                category?: Maybe<
                  { __typename?: 'Category' } & Pick<Category, 'name'>
                >
                toAccount?: Maybe<
                  { __typename?: 'Account' } & Pick<Account, 'name'>
                >
                fromAccount?: Maybe<
                  { __typename?: 'Account' } & Pick<Account, 'name'>
                >
              }
          >
        >
      >
    }
  >
}

export type SummaryQueryVariables = Exact<{
  userId: Scalars['String']
  year: Scalars['Date']
  includeUnusual: Scalars['Boolean']
}>

export type SummaryQuery = { __typename?: 'Query' } & {
  summary?: Maybe<
    { __typename?: 'SummaryResponse' } & {
      assets?: Maybe<
        Array<
          Maybe<
            { __typename?: 'AssetResponse' } & Pick<
              AssetResponse,
              'id' | 'name' | 'startingBalance' | 'main'
            > & {
                months?: Maybe<
                  { __typename?: 'AssetMonthsResponse' } & Pick<
                    AssetMonthsResponse,
                    | 'Jan'
                    | 'Feb'
                    | 'Mar'
                    | 'Apr'
                    | 'May'
                    | 'Jun'
                    | 'Jul'
                    | 'Aug'
                    | 'Sep'
                    | 'Oct'
                    | 'Nov'
                    | 'Dec'
                  >
                >
              }
          >
        >
      >
      expenses?: Maybe<
        Array<
          Maybe<
            { __typename?: 'ParentCategoryExpense' } & Pick<
              ParentCategoryExpense,
              'key' | 'categoryName' | 'id'
            > & {
                children?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'ChildCategoryExpense' } & Pick<
                        ChildCategoryExpense,
                        'key' | 'categoryName' | 'id'
                      > & {
                          months?: Maybe<
                            { __typename?: 'CategoryMonthsResponse' } & Pick<
                              CategoryMonthsResponse,
                              | 'Jan'
                              | 'Feb'
                              | 'Mar'
                              | 'Apr'
                              | 'May'
                              | 'Jun'
                              | 'Jul'
                              | 'Aug'
                              | 'Sep'
                              | 'Oct'
                              | 'Nov'
                              | 'Dec'
                            >
                          >
                        }
                    >
                  >
                >
              }
          >
        >
      >
      transfers?: Maybe<
        Array<
          Maybe<
            { __typename?: 'ParentCategoryTransfer' } & Pick<
              ParentCategoryTransfer,
              'key' | 'categoryName' | 'id'
            > & {
                children?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'ChildCategoryTransfer' } & Pick<
                        ChildCategoryTransfer,
                        'key' | 'categoryName' | 'id'
                      > & {
                          months?: Maybe<
                            { __typename?: 'CategoryMonthsResponse' } & Pick<
                              CategoryMonthsResponse,
                              | 'Jan'
                              | 'Feb'
                              | 'Mar'
                              | 'Apr'
                              | 'May'
                              | 'Jun'
                              | 'Jul'
                              | 'Aug'
                              | 'Sep'
                              | 'Oct'
                              | 'Nov'
                              | 'Dec'
                            >
                          >
                        }
                    >
                  >
                >
              }
          >
        >
      >
      income?: Maybe<
        Array<
          Maybe<
            { __typename?: 'ParentCategoryIncome' } & Pick<
              ParentCategoryIncome,
              'key' | 'categoryName' | 'id'
            > & {
                children?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'ChildCategoryIncome' } & Pick<
                        ChildCategoryIncome,
                        'key' | 'categoryName' | 'id'
                      > & {
                          months?: Maybe<
                            { __typename?: 'CategoryMonthsResponse' } & Pick<
                              CategoryMonthsResponse,
                              | 'Jan'
                              | 'Feb'
                              | 'Mar'
                              | 'Apr'
                              | 'May'
                              | 'Jun'
                              | 'Jul'
                              | 'Aug'
                              | 'Sep'
                              | 'Oct'
                              | 'Nov'
                              | 'Dec'
                            >
                          >
                        }
                    >
                  >
                >
              }
          >
        >
      >
    }
  >
}

export type UsersQueryVariables = Exact<{ [key: string]: never }>

export type UsersQuery = { __typename?: 'Query' } & {
  users?: Maybe<Array<Maybe<{ __typename?: 'User' } & UserFragmentFragment>>>
}

export const UserFragmentFragmentDoc = gql`
  fragment UserFragment on User {
    id
    email
    updatedDate
    createdDate
    verified
  }
`
export const AccountFragmentFragmentDoc = gql`
  fragment AccountFragment on Account {
    id
    name
    startingBalance
    main
    user {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`
export const CategoryFragmentFragmentDoc = gql`
  fragment CategoryFragment on Category {
    id
    name
    parent {
      id
    }
    user {
      ...UserFragment
    }
    updatedDate
    createdDate
  }
  ${UserFragmentFragmentDoc}
`
export const DatamapFragmentFragmentDoc = gql`
  fragment DatamapFragment on Datamap {
    id
    originKey
    newKey
    user {
      ...UserFragment
    }
    updatedDate
    createdDate
  }
  ${UserFragmentFragmentDoc}
`
export const DatarowFragmentFragmentDoc = gql`
  fragment DatarowFragment on Datarow {
    id
    amount
    transactionDate
    identifier
    description
    isUnusual
    transactionType
    toAccount {
      id
      main
    }
    fromAccount {
      id
      main
    }
    user {
      id
    }
    updatedDate
    createdDate
  }
`
export const ChangePasswordDocument = gql`
  mutation ChangePassword($token: String!, $newPassword: String!) {
    changePassword(token: $token, newPassword: $newPassword) {
      fieldError {
        field
        message
      }
      user {
        ...UserFragment
      }
    }
  }
  ${UserFragmentFragmentDoc}
`
export type ChangePasswordMutationFn = Apollo.MutationFunction<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >(ChangePasswordDocument, options)
}
export type ChangePasswordMutationHookResult = ReturnType<
  typeof useChangePasswordMutation
>
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>
export const CreateAccountDocument = gql`
  mutation CreateAccount(
    $userId: String!
    $startingBalance: Int!
    $name: String!
    $main: Boolean!
  ) {
    createAccount(
      userId: $userId
      startingBalance: $startingBalance
      name: $name
      main: $main
    ) {
      ...AccountFragment
    }
  }
  ${AccountFragmentFragmentDoc}
`
export type CreateAccountMutationFn = Apollo.MutationFunction<
  CreateAccountMutation,
  CreateAccountMutationVariables
>

/**
 * __useCreateAccountMutation__
 *
 * To run a mutation, you first call `useCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreateAccountMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      startingBalance: // value for 'startingBalance'
 *      name: // value for 'name'
 *      main: // value for 'main'
 *   },
 * });
 */
export function useCreateAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAccountMutation,
    CreateAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateAccountMutation,
    CreateAccountMutationVariables
  >(CreateAccountDocument, options)
}
export type CreateAccountMutationHookResult = ReturnType<
  typeof useCreateAccountMutation
>
export type CreateAccountMutationResult = Apollo.MutationResult<CreateAccountMutation>
export type CreateAccountMutationOptions = Apollo.BaseMutationOptions<
  CreateAccountMutation,
  CreateAccountMutationVariables
>
export const CreateDatamapsDocument = gql`
  mutation CreateDatamaps($userId: String, $data: [DatamapsCreateInput!]!) {
    createDatamaps(userId: $userId, data: $data) {
      ...DatamapFragment
    }
  }
  ${DatamapFragmentFragmentDoc}
`
export type CreateDatamapsMutationFn = Apollo.MutationFunction<
  CreateDatamapsMutation,
  CreateDatamapsMutationVariables
>

/**
 * __useCreateDatamapsMutation__
 *
 * To run a mutation, you first call `useCreateDatamapsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDatamapsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDatamapsMutation, { data, loading, error }] = useCreateDatamapsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDatamapsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDatamapsMutation,
    CreateDatamapsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateDatamapsMutation,
    CreateDatamapsMutationVariables
  >(CreateDatamapsDocument, options)
}
export type CreateDatamapsMutationHookResult = ReturnType<
  typeof useCreateDatamapsMutation
>
export type CreateDatamapsMutationResult = Apollo.MutationResult<CreateDatamapsMutation>
export type CreateDatamapsMutationOptions = Apollo.BaseMutationOptions<
  CreateDatamapsMutation,
  CreateDatamapsMutationVariables
>
export const CreateDatarowsDocument = gql`
  mutation CreateDatarows($userId: String, $data: [DatarowsCreateInput!]!) {
    createDatarows(userId: $userId, data: $data) {
      ...DatarowFragment
    }
  }
  ${DatarowFragmentFragmentDoc}
`
export type CreateDatarowsMutationFn = Apollo.MutationFunction<
  CreateDatarowsMutation,
  CreateDatarowsMutationVariables
>

/**
 * __useCreateDatarowsMutation__
 *
 * To run a mutation, you first call `useCreateDatarowsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDatarowsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDatarowsMutation, { data, loading, error }] = useCreateDatarowsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDatarowsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDatarowsMutation,
    CreateDatarowsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateDatarowsMutation,
    CreateDatarowsMutationVariables
  >(CreateDatarowsDocument, options)
}
export type CreateDatarowsMutationHookResult = ReturnType<
  typeof useCreateDatarowsMutation
>
export type CreateDatarowsMutationResult = Apollo.MutationResult<CreateDatarowsMutation>
export type CreateDatarowsMutationOptions = Apollo.BaseMutationOptions<
  CreateDatarowsMutation,
  CreateDatarowsMutationVariables
>
export const CreateUserDocument = gql`
  mutation CreateUser($email: String!, $password: String!) {
    createUser(email: $email, password: $password) {
      fieldError {
        field
        message
      }
      user {
        ...UserFragment
      }
    }
  }
  ${UserFragmentFragmentDoc}
`
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserMutation,
    CreateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options,
  )
}
export type CreateUserMutationHookResult = ReturnType<
  typeof useCreateUserMutation
>
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>
export const ForgotPasswordDocument = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`
export type ForgotPasswordMutationFn = Apollo.MutationFunction<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ForgotPasswordMutation,
    ForgotPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ForgotPasswordMutation,
    ForgotPasswordMutationVariables
  >(ForgotPasswordDocument, options)
}
export type ForgotPasswordMutationHookResult = ReturnType<
  typeof useForgotPasswordMutation
>
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>
export const LoginDocument = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      fieldError {
        field
        message
      }
      user {
        ...UserFragment
      }
    }
  }
  ${UserFragmentFragmentDoc}
`
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options,
  )
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>
export const LogoutDocument = gql`
  mutation Logout {
    logout
  }
`
export type LogoutMutationFn = Apollo.MutationFunction<
  LogoutMutation,
  LogoutMutationVariables
>

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LogoutMutation,
    LogoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(
    LogoutDocument,
    options,
  )
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>
export type LogoutMutationOptions = Apollo.BaseMutationOptions<
  LogoutMutation,
  LogoutMutationVariables
>
export const VerifyDocument = gql`
  mutation Verify($token: String!) {
    verify(token: $token) {
      fieldError {
        field
        message
      }
      user {
        ...UserFragment
      }
    }
  }
  ${UserFragmentFragmentDoc}
`
export type VerifyMutationFn = Apollo.MutationFunction<
  VerifyMutation,
  VerifyMutationVariables
>

/**
 * __useVerifyMutation__
 *
 * To run a mutation, you first call `useVerifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyMutation, { data, loading, error }] = useVerifyMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyMutation,
    VerifyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<VerifyMutation, VerifyMutationVariables>(
    VerifyDocument,
    options,
  )
}
export type VerifyMutationHookResult = ReturnType<typeof useVerifyMutation>
export type VerifyMutationResult = Apollo.MutationResult<VerifyMutation>
export type VerifyMutationOptions = Apollo.BaseMutationOptions<
  VerifyMutation,
  VerifyMutationVariables
>
export const AccountsDocument = gql`
  query Accounts($userId: String!) {
    accounts(userId: $userId) {
      ...AccountFragment
    }
  }
  ${AccountFragmentFragmentDoc}
`

/**
 * __useAccountsQuery__
 *
 * To run a query within a React component, call `useAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAccountsQuery(
  baseOptions: Apollo.QueryHookOptions<AccountsQuery, AccountsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AccountsQuery, AccountsQueryVariables>(
    AccountsDocument,
    options,
  )
}
export function useAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountsQuery,
    AccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AccountsQuery, AccountsQueryVariables>(
    AccountsDocument,
    options,
  )
}
export type AccountsQueryHookResult = ReturnType<typeof useAccountsQuery>
export type AccountsLazyQueryHookResult = ReturnType<
  typeof useAccountsLazyQuery
>
export type AccountsQueryResult = Apollo.QueryResult<
  AccountsQuery,
  AccountsQueryVariables
>
export const CategoriesDocument = gql`
  query Categories($userId: String!) {
    categories(userId: $userId) {
      ...CategoryFragment
      children {
        ...CategoryFragment
      }
    }
  }
  ${CategoryFragmentFragmentDoc}
`

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    CategoriesQuery,
    CategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CategoriesQuery, CategoriesQueryVariables>(
    CategoriesDocument,
    options,
  )
}
export function useCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CategoriesQuery,
    CategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(
    CategoriesDocument,
    options,
  )
}
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>
export type CategoriesLazyQueryHookResult = ReturnType<
  typeof useCategoriesLazyQuery
>
export type CategoriesQueryResult = Apollo.QueryResult<
  CategoriesQuery,
  CategoriesQueryVariables
>
export const CategoryParentsDocument = gql`
  query CategoryParents($userId: String!) {
    categoryParents(userId: $userId) {
      ...CategoryFragment
      children {
        ...CategoryFragment
      }
    }
  }
  ${CategoryFragmentFragmentDoc}
`

/**
 * __useCategoryParentsQuery__
 *
 * To run a query within a React component, call `useCategoryParentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryParentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryParentsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCategoryParentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CategoryParentsQuery,
    CategoryParentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CategoryParentsQuery, CategoryParentsQueryVariables>(
    CategoryParentsDocument,
    options,
  )
}
export function useCategoryParentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CategoryParentsQuery,
    CategoryParentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CategoryParentsQuery,
    CategoryParentsQueryVariables
  >(CategoryParentsDocument, options)
}
export type CategoryParentsQueryHookResult = ReturnType<
  typeof useCategoryParentsQuery
>
export type CategoryParentsLazyQueryHookResult = ReturnType<
  typeof useCategoryParentsLazyQuery
>
export type CategoryParentsQueryResult = Apollo.QueryResult<
  CategoryParentsQuery,
  CategoryParentsQueryVariables
>
export const DatamapsDocument = gql`
  query Datamaps($userId: String!) {
    datamaps(userId: $userId) {
      ...DatamapFragment
    }
  }
  ${DatamapFragmentFragmentDoc}
`

/**
 * __useDatamapsQuery__
 *
 * To run a query within a React component, call `useDatamapsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatamapsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatamapsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDatamapsQuery(
  baseOptions: Apollo.QueryHookOptions<DatamapsQuery, DatamapsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DatamapsQuery, DatamapsQueryVariables>(
    DatamapsDocument,
    options,
  )
}
export function useDatamapsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DatamapsQuery,
    DatamapsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DatamapsQuery, DatamapsQueryVariables>(
    DatamapsDocument,
    options,
  )
}
export type DatamapsQueryHookResult = ReturnType<typeof useDatamapsQuery>
export type DatamapsLazyQueryHookResult = ReturnType<
  typeof useDatamapsLazyQuery
>
export type DatamapsQueryResult = Apollo.QueryResult<
  DatamapsQuery,
  DatamapsQueryVariables
>
export const DatarowsDocument = gql`
  query Datarows($userId: String!) {
    datarows(userId: $userId) {
      ...DatarowFragment
      category {
        id
      }
    }
  }
  ${DatarowFragmentFragmentDoc}
`

/**
 * __useDatarowsQuery__
 *
 * To run a query within a React component, call `useDatarowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatarowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatarowsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDatarowsQuery(
  baseOptions: Apollo.QueryHookOptions<DatarowsQuery, DatarowsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DatarowsQuery, DatarowsQueryVariables>(
    DatarowsDocument,
    options,
  )
}
export function useDatarowsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DatarowsQuery,
    DatarowsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DatarowsQuery, DatarowsQueryVariables>(
    DatarowsDocument,
    options,
  )
}
export type DatarowsQueryHookResult = ReturnType<typeof useDatarowsQuery>
export type DatarowsLazyQueryHookResult = ReturnType<
  typeof useDatarowsLazyQuery
>
export type DatarowsQueryResult = Apollo.QueryResult<
  DatarowsQuery,
  DatarowsQueryVariables
>
export const IsLoggedInDocument = gql`
  query IsLoggedIn {
    isLoggedIn {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`

/**
 * __useIsLoggedInQuery__
 *
 * To run a query within a React component, call `useIsLoggedInQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsLoggedInQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsLoggedInQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsLoggedInQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IsLoggedInQuery,
    IsLoggedInQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IsLoggedInQuery, IsLoggedInQueryVariables>(
    IsLoggedInDocument,
    options,
  )
}
export function useIsLoggedInLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IsLoggedInQuery,
    IsLoggedInQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IsLoggedInQuery, IsLoggedInQueryVariables>(
    IsLoggedInDocument,
    options,
  )
}
export type IsLoggedInQueryHookResult = ReturnType<typeof useIsLoggedInQuery>
export type IsLoggedInLazyQueryHookResult = ReturnType<
  typeof useIsLoggedInLazyQuery
>
export type IsLoggedInQueryResult = Apollo.QueryResult<
  IsLoggedInQuery,
  IsLoggedInQueryVariables
>
export const MonthSummaryDocument = gql`
  query MonthSummary(
    $userId: String!
    $year: Date!
    $includeUnusual: Boolean!
  ) {
    monthSummary(
      userId: $userId
      year: $year
      includeUnusual: $includeUnusual
    ) {
      assets {
        id
        name
        startingBalance
        main
        month
      }
      expenses {
        key
        categoryName
        id
        children {
          key
          categoryName
          id
          month
        }
      }
      transfers {
        key
        categoryName
        id
        children {
          key
          categoryName
          id
          month
        }
      }
      income {
        key
        categoryName
        id
        children {
          key
          categoryName
          id
          month
        }
      }
      datarows {
        id
        amount
        category {
          name
        }
        transactionDate
        identifier
        description
        isUnusual
        transactionType
        toAccount {
          name
        }
        fromAccount {
          name
        }
      }
    }
  }
`

/**
 * __useMonthSummaryQuery__
 *
 * To run a query within a React component, call `useMonthSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonthSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonthSummaryQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      year: // value for 'year'
 *      includeUnusual: // value for 'includeUnusual'
 *   },
 * });
 */
export function useMonthSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    MonthSummaryQuery,
    MonthSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MonthSummaryQuery, MonthSummaryQueryVariables>(
    MonthSummaryDocument,
    options,
  )
}
export function useMonthSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MonthSummaryQuery,
    MonthSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MonthSummaryQuery, MonthSummaryQueryVariables>(
    MonthSummaryDocument,
    options,
  )
}
export type MonthSummaryQueryHookResult = ReturnType<
  typeof useMonthSummaryQuery
>
export type MonthSummaryLazyQueryHookResult = ReturnType<
  typeof useMonthSummaryLazyQuery
>
export type MonthSummaryQueryResult = Apollo.QueryResult<
  MonthSummaryQuery,
  MonthSummaryQueryVariables
>
export const SummaryDocument = gql`
  query Summary($userId: String!, $year: Date!, $includeUnusual: Boolean!) {
    summary(userId: $userId, year: $year, includeUnusual: $includeUnusual) {
      assets {
        id
        name
        startingBalance
        main
        months {
          Jan
          Feb
          Mar
          Apr
          May
          Jun
          Jul
          Aug
          Sep
          Oct
          Nov
          Dec
        }
      }
      expenses {
        key
        categoryName
        id
        children {
          key
          categoryName
          id
          months {
            Jan
            Feb
            Mar
            Apr
            May
            Jun
            Jul
            Aug
            Sep
            Oct
            Nov
            Dec
          }
        }
      }
      transfers {
        key
        categoryName
        id
        children {
          key
          categoryName
          id
          months {
            Jan
            Feb
            Mar
            Apr
            May
            Jun
            Jul
            Aug
            Sep
            Oct
            Nov
            Dec
          }
        }
      }
      income {
        key
        categoryName
        id
        children {
          key
          categoryName
          id
          months {
            Jan
            Feb
            Mar
            Apr
            May
            Jun
            Jul
            Aug
            Sep
            Oct
            Nov
            Dec
          }
        }
      }
    }
  }
`

/**
 * __useSummaryQuery__
 *
 * To run a query within a React component, call `useSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummaryQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      year: // value for 'year'
 *      includeUnusual: // value for 'includeUnusual'
 *   },
 * });
 */
export function useSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<SummaryQuery, SummaryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SummaryQuery, SummaryQueryVariables>(
    SummaryDocument,
    options,
  )
}
export function useSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SummaryQuery,
    SummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SummaryQuery, SummaryQueryVariables>(
    SummaryDocument,
    options,
  )
}
export type SummaryQueryHookResult = ReturnType<typeof useSummaryQuery>
export type SummaryLazyQueryHookResult = ReturnType<typeof useSummaryLazyQuery>
export type SummaryQueryResult = Apollo.QueryResult<
  SummaryQuery,
  SummaryQueryVariables
>
export const UsersDocument = gql`
  query Users {
    users {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UsersQuery, UsersQueryVariables>(
    UsersDocument,
    options,
  )
}
export function useUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(
    UsersDocument,
    options,
  )
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>
export type UsersQueryResult = Apollo.QueryResult<
  UsersQuery,
  UsersQueryVariables
>
