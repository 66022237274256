import { useEffect } from 'react'
import { useState } from 'react'
import { Bar } from 'react-chartjs-2'

import { DatarowsCreateInput, Query } from '../../../../../generated/graphql'
import { createDatasets } from './methods'

export interface CategoryChartProps {
  rows: DatarowsCreateInput[] | null
  datarows?: Query['datarows']
  categoryParents: Query['categoryParents']
}

export interface CreateDatasetsProps extends CategoryChartProps {
  category: string
}

export interface Dataset {
  x: string
  y: number
}

export interface Datasets {
  lastMonth: Dataset[] | undefined
  thisMonth: Dataset[] | undefined
}

const CategoryChart: React.FC<CategoryChartProps> = ({
  rows,
  datarows,
  categoryParents,
}: CategoryChartProps) => {
  const [category, setCategory] = useState<string>('Parents')
  const [datasets, setDatasets] = useState<Datasets>(
    createDatasets({
      rows,
      datarows,
      categoryParents,
      category,
    }),
  )

  const handleCategoryUpdate: (newCategory: string) => void = (
    newCategory: string,
  ) => {
    const isParent = categoryParents?.some(
      parent => parent?.name === newCategory,
    )

    if (isParent) {
      setCategory(newCategory)
    } else {
      setCategory('Parents')
    }
  }

  useEffect(() => {
    if (category) {
      const newSet = createDatasets({
        rows,
        datarows,
        categoryParents,
        category,
      })
      setDatasets(newSet)
    }
  }, [category, rows, datarows, categoryParents])

  return (
    <div style={{ width: '100%', margin: '48px 0' }}>
      <Bar
        data={{
          labels: datasets?.thisMonth?.map(set => set.x),
          datasets: [
            {
              label: 'This Month',
              data: datasets?.thisMonth || [],
              backgroundColor: 'rgba(0, 255, 152, 0.2)',
              borderColor: 'rgba(0, 255, 152, 1)',
              borderWidth: 1,
            },
            ...(datasets?.lastMonth
              ? [
                  {
                    label: 'Last Month',
                    data: datasets.lastMonth,
                    backgroundColor: 'rgba(196, 30, 58, 0.2)',
                    borderColor: 'rgba(196, 30, 58, 1)',
                    borderWidth: 1,
                  },
                ]
              : []),
          ],
        }}
        options={{
          onClick: (e: any, el: any) => {
            const newCategory = el?.[0]?.element?.$context?.raw?.x
            if (newCategory) {
              handleCategoryUpdate(newCategory)
            }
          },
          scales: {
            x: {
              ticks: {
                color: '#fff',
              },
              grid: {
                display: false,
              },
            },
            y: {
              ticks: {
                color: '#fff',
              },
              grid: {
                display: false,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
      />
    </div>
  )
}

export default CategoryChart
