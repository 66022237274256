import { endOfMonth } from 'date-fns'

import { TransactionType } from '../../../../../generated/graphql'
import { CreateDatasetsProps, Datasets } from './CategoryChart'

export const createDatasets: ({
  rows,
  datarows,
  categoryParents,
  category,
}: CreateDatasetsProps) => Datasets = ({
  rows,
  datarows,
  categoryParents,
  category = 'Parents',
}: CreateDatasetsProps) => {
  const expenseDatarows = rows?.filter(
    row => row?.transactionType === TransactionType.expense,
  )

  const lastMonth = rows?.reduce((acc, row) => {
    const startDate = new Date(row?.transactionDate)
    startDate.setDate(1)
    startDate.setMonth(startDate.getMonth() - 1)

    acc[`${startDate.getMonth()}`] = acc[`${startDate.getMonth()}`]
      ? {
          count: acc[`${startDate.getMonth()}`].count + 1,
          startDate: startDate,
          endDate: endOfMonth(startDate),
        }
      : { count: 1, startDate: startDate, endDate: endOfMonth(startDate) }

    return acc
  }, {} as any)

  const getMostCountedMonth = Object.keys(lastMonth).find(
    key =>
      lastMonth[key].count ===
      Math.max(...Object.keys(lastMonth).map(key => lastMonth[key].count)),
  )

  const lastMonthsDataRow = datarows?.filter(row => {
    const date = new Date(row?.transactionDate)
    return (
      date >= lastMonth[getMostCountedMonth as string]?.startDate &&
      date <= lastMonth[getMostCountedMonth as string]?.endDate
    )
  })

  if (category === 'Parents') {
    const datasets = categoryParents
      ?.map(category => {
        const getExpensesForCategory = expenseDatarows?.filter(expense => {
          const matchId = category?.children?.some(
            child => child?.id === expense?.category,
          )
          return matchId
        })

        const getExpensesForCategoryLastMonth = lastMonthsDataRow?.filter(
          row => {
            if (row?.transactionType !== TransactionType.expense) {
              return false
            } else {
              const matchId = category?.children?.some(
                child => child?.id === row?.category?.id,
              )
              return matchId
            }
          },
        )

        return {
          thisMonth:
            getExpensesForCategory?.reduce(
              (acc, expense) => acc + Math.abs(expense?.amount),
              0,
            ) || 0,
          lastMonth:
            getExpensesForCategoryLastMonth?.reduce(
              (acc, expense) =>
                expense?.amount ? acc + Math.abs(expense?.amount) : acc,
              0,
            ) || 0,
          name: category?.name || '',
        }
      })
      ?.filter(category => category?.thisMonth > 0 || category?.lastMonth > 0)

    return {
      thisMonth: datasets?.map(category => ({
        x: category?.name,
        y: category?.thisMonth,
      })),
      lastMonth: datasets?.map(category => ({
        x: category?.name,
        y: category?.lastMonth,
      })),
    }
  } else {
    const datasets = categoryParents
      ?.find(_category => _category?.name === category)
      ?.children?.map(_child => {
        const getExpensesForCategory = expenseDatarows?.filter(
          expense => expense?.category === _child?.id,
        )

        const getExpensesForCategoryLastMonth = lastMonthsDataRow?.filter(
          row =>
            row?.transactionType === TransactionType.expense &&
            row?.category?.id === _child?.id,
        )

        return {
          thisMonth:
            getExpensesForCategory?.reduce(
              (acc, expense) => acc + Math.abs(expense?.amount),
              0,
            ) || 0,
          lastMonth:
            getExpensesForCategoryLastMonth?.reduce(
              (acc, expense) =>
                expense?.amount ? acc + Math.abs(expense?.amount) : acc,
              0,
            ) || 0,
          name: _child?.name || '',
        }
      })
      ?.filter(category => category?.thisMonth > 0 || category?.lastMonth > 0)

    return {
      thisMonth: datasets?.map(category => ({
        x: category?.name,
        y: category?.thisMonth,
      })),
      lastMonth: datasets?.map(category => ({
        x: category?.name,
        y: category?.lastMonth,
      })),
    }
  }
}
