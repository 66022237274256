import { GetStepType } from './../index.d'
import FileStep from './FileStep'
import MappingStep from './MappingStep'
import ResultStep from './ResultStep/'
import TableStep from './TableStep/'

export const getStepComponent: GetStepType = props => {
  const { activeStep } = props
  switch (activeStep) {
    case 0:
      return <FileStep {...props} />
    case 1:
      return <MappingStep {...props} />
    case 2:
      return <TableStep {...props} />
    case 3:
      return <ResultStep {...props} />
    default:
      return <FileStep {...props} />
  }
}
