import {
  DatamapsCreateInput,
  DatarowsCreateInput,
} from '../../../generated/graphql'

export interface ParsedData {
  data: any[]
  meta: ParsedDataMeta
}

export interface ParsedDataMeta {
  aborted: boolean
  cursor: number
  delimiter: string
  fields: string[]
  linebreak: string
  truncated: boolean
}

export interface SubmittedData {
  file: ParsedData | null
  maps: DatamapsCreateInput[] | null
  rows: DatarowsCreateInput[] | null
}

export type FormAction =
  | { type: ActionTypes.File; payload: ParsedData }
  | { type: ActionTypes.Maps; payload: DatamapsCreateInput[] }
  | { type: ActionTypes.Rows; payload: DatarowsCreateInput[] }
  | { type: ActionTypes.Reset; payload: SubmittedData }

export type ReducerFn = (
  state: SubmittedData,
  action: FormAction,
) => SubmittedData

export interface StepProps {
  activeStep: number
  handleNext: () => void
  formValues: SubmittedData
  setFormValues: React.Dispatch<FormAction>
}

export interface CascaderOptions {
  value: string
  label: string
  parent: string
}

export type GetStepType = (props: StepProps) => JSX.Element

export interface FileStepInputs {
  fileData: ParsedData
}

export interface DropzoneProps {
  onChange: (...event: any[]) => void
}

export interface MappingStepInputs {
  mapping: DatamapsCreateInput[]
}

type ExtendedDatarowsCreateInput = {
  category: {
    value: string
    label: string
    parent: string
  }
} & DatarowsCreateInput
export interface TableStepInputs {
  rows: ExtendedDatarowsCreateInput[]
}

export enum ActionTypes {
  File = 'SET_FILE',
  Maps = 'SET_MAPS',
  Rows = 'SET_ROWS',
  Reset = 'RESET',
}
