import { useDatarowsQuery } from '../../../../../generated/graphql'
import { useAuth } from '../../../../../hooks/useAuth'
import { StepProps } from '../../index.d'
import TableForm from './TableForm'

const TableStep: React.FC<StepProps> = props => {
  const { user } = useAuth()

  if (!user) return null
  const { data: { datarows } = {}, loading } = useDatarowsQuery({
    variables: {
      userId: user.id,
    },
  })

  if (loading) return <p>Loading...</p>

  return <TableForm {...props} datarows={datarows} />
}

export default TableStep
