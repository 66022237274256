import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useEffect, useState } from 'react'

import { labels } from './methods'

type AccordionProps = {
  data: any
  title: string
}

const ExpensesAccordion: React.FC<AccordionProps> = ({
  data,
  title,
}: AccordionProps) => {
  const [expenses, setExpenses] = useState<any>()
  useEffect(() => {
    if (data) {
      const tableData = data?.map((parent: any) => {
        const monthly = labels?.map(monthKey => {
          const monthParentTotal = parent?.children?.reduce(
            (childAcc: number, child: any) => {
              if (!child?.months?.[monthKey]) return childAcc
              const totalChildMonth = child?.months?.[monthKey]?.reduce(
                (monthAcc: number, monthNr: number) => monthAcc + monthNr,
                0 as number,
              )
              return childAcc + totalChildMonth
            },
            0,
          )
          return monthParentTotal
        })

        const totalMonthly = monthly?.reduce((acc, nr) => acc + nr, 0)
        const avgMonthly = totalMonthly / monthly?.length

        return [parent?.categoryName, ...monthly, totalMonthly, avgMonthly]
      })

      setExpenses(tableData)
    }
  }, [data])

  const headers = ['', ...labels, 'Total', 'Average']
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography style={{ textTransform: 'uppercase' }}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {headers?.map((header, i) => (
                  <TableCell align="right" key={i}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {expenses?.map((row: any, i: number) => (
                <TableRow key={i}>
                  {row?.map((col: any, j: number) => {
                    if (typeof col === 'string') {
                      return (
                        <TableCell key={j} align="left">
                          {col}
                        </TableCell>
                      )
                    }

                    return (
                      <TableCell key={j} align="right">
                        {col.toFixed(2)} kr
                      </TableCell>
                    )
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  )
}

export default ExpensesAccordion
