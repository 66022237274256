import { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'

import { createDataset, labels } from './methods'

const options = {
  scales: {
    y: {
      beginAtZero: true,
    },
  },
}

export type SummaryProps = {
  summary: any
}

const SummaryLine: React.FC<SummaryProps> = ({ summary }: SummaryProps) => {
  const [expenseDataset, setExpenseDataset] = useState({})
  const [incomeDataset, setIncomeDataset] = useState({})
  const [transferDataset, setTransferDataset] = useState({})
  const [savingsDataset, setSavingsDataset] = useState({})

  useEffect(() => {
    if (summary) {
      const expenses = createDataset(summary?.expenses)
      const income = createDataset(summary?.income)
      const savings = expenses?.map((monthNr, i) => income[i] - monthNr)

      setExpenseDataset({
        label: 'Expenses',
        fill: false,
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: 'rgba(255, 99, 132, 0.2)',
        data: expenses,
      })
      setIncomeDataset({
        label: 'Income',
        fill: false,
        backgroundColor: 'rgb(156, 255, 99)',
        borderColor: 'rgba(117, 255, 99, 0.2)',
        data: income,
      })
      setTransferDataset({
        label: 'Transfers',
        fill: false,
        backgroundColor: 'rgb(99, 185, 255)',
        borderColor: 'rgba(99, 148, 255, 0.2)',
        data: createDataset(summary?.transfers),
      })

      setSavingsDataset({
        label: 'Savings',
        fill: false,
        backgroundColor: 'rgb(255, 199, 98)',
        borderColor: 'rgba(255, 210, 98, 0.2)',
        data: savings,
      })
    }
  }, [summary])

  return (
    <div style={{ padding: '40px' }}>
      <Line
        height={100}
        data={{
          labels,
          datasets: [
            expenseDataset,
            incomeDataset,
            transferDataset,
            savingsDataset,
          ],
        }}
        options={options}
      />
    </div>
  )
}

export default SummaryLine
