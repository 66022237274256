import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useEffect, useState } from 'react'

import { labels } from './methods'

type AccountProps = {
  accounts: any
}

const AccountAccordion: React.FC<AccountProps> = ({
  accounts,
}: AccountProps) => {
  const [newAccounts, setNewAccounts] = useState<any>()

  const headers = ['', ...labels, 'Starting', 'Average']

  useEffect(() => {
    if (accounts) {
      const formattedAccounts = accounts?.map((account: any) => {
        const monthly = labels?.map((_, i, original) => {
          const subLabels = original?.slice(0, i + 1)

          const currentTotal = subLabels?.reduce(
            (acc, month) => acc + (account?.months?.[month] ?? 0),
            0,
          )

          return account?.startingBalance + currentTotal
        })

        const total = monthly?.reduce((acc, month) => acc + month, 0)
        const avg = total / monthly?.length

        return [account?.name, ...monthly, account?.startingBalance, avg]
      })

      setNewAccounts(formattedAccounts)
    }
  }, [accounts])

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography style={{ textTransform: 'uppercase' }}>Accounts</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {headers?.map((header, i) => (
                  <TableCell align="right" key={i}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {newAccounts?.map((row: any, i: number) => (
                <TableRow key={i}>
                  {row?.map((col: any, j: number) => {
                    if (typeof col === 'string') {
                      return (
                        <TableCell key={j} align="left">
                          {col}
                        </TableCell>
                      )
                    }

                    return (
                      <TableCell key={j} align="right">
                        {col.toFixed()} kr
                      </TableCell>
                    )
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  )
}

export default AccountAccordion
