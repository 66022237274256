import { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'

const getTotalFromArray: (data: any) => number = (data: any) => {
  const total = data?.reduce((expAcc: number, exp: any) => {
    const children = exp?.children?.reduce((childAcc: number, child: any) => {
      const months = child?.month?.reduce(
        (monthAcc: number, month: number) => monthAcc + month,
        0,
      )

      return childAcc + months
    }, 0)

    return expAcc + children
  }, 0)
  return total
}

const AbsAmountTransactionType = ({ data }: { data: any }): JSX.Element => {
  const [datasets, setDatasets] = useState<number[]>()

  useEffect(() => {
    if (data) {
      const expense = getTotalFromArray(data?.expenses)
      const transfer = getTotalFromArray(data?.transfers)
      const income = getTotalFromArray(data?.income)

      setDatasets([expense, transfer, income])
    }
  }, [data])

  return (
    <div style={{ width: 'calc(50% - 24px)', marginLeft: '24px' }}>
      <Bar
        data={{
          labels: ['Expenses', 'Transfers', 'Income'],
          datasets: [
            {
              label: 'SEK',
              yAxisID: 'yOne',
              data: datasets,
              backgroundColor: 'rgba(0, 255, 152, 0.2)',
              borderColor: 'rgba(0, 255, 152, 1)',
              borderWidth: 1,
            },
            {
              label: 'SEK',
              yAxisID: 'yTwo',
              data: datasets,
              hidden: true,
              backgroundColor: 'rgba(0, 255, 152, 0.2)',
              borderColor: 'rgba(0, 255, 152, 1)',
              borderWidth: 1,
            },
          ],
        }}
        options={{
          indexAxis: 'y',
          scales: {
            x: {
              ticks: {
                display: false,
              },
              grid: {
                display: false,
                drawBorder: false,
              },
            },
            yTwo: {
              ticks: {
                color: '#fff',

                font: {
                  size: 14,
                },
                callback: (_: any, index: any) => {
                  const obj = datasets?.[index]

                  return `${obj?.toFixed(2)} SEK`
                },
              },
              grid: {
                display: false,
                drawBorder: false,
              },
            },
            yOne: {
              ticks: {
                color: '#fff',
                crossAlign: 'far',
                font: {
                  size: 14,
                },
              },
              grid: {
                display: false,
                drawBorder: false,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
      />
    </div>
  )
}

export default AbsAmountTransactionType
