import { Button, Card, makeStyles } from '@material-ui/core'
import PapaParse from 'papaparse'
import { useDropzone } from 'react-dropzone'
import { Controller, useForm } from 'react-hook-form'

import {
  ActionTypes,
  DropzoneProps,
  FileStepInputs,
  StepProps,
} from './../index.d'

const useStyles = makeStyles({
  root: {
    margin: '20px 0',
    height: '200px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    border: '1px dashed white',
    width: '100%',
  },
})

const FileStep: React.FC<StepProps> = ({
  handleNext,
  setFormValues,
}: StepProps) => {
  const { handleSubmit, control, watch } = useForm<FileStepInputs>()

  const fileData = watch('fileData')

  const onSubmit = (data: FileStepInputs) => {
    setFormValues({ type: ActionTypes.File, payload: data?.fileData })
    handleNext()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="fileData"
        control={control}
        rules={{ required: true }}
        render={({ onChange }) => {
          return <Dropzone onChange={onChange} />
        }}
      />
      <div
        style={{
          width: 'calc(100% - 40px)',
          margin: '20px auto',
          maxWidth: '600px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}>
        <Button
          variant="outlined"
          color="primary"
          type="submit"
          disabled={!fileData}>
          Next
        </Button>
      </div>
    </form>
  )
}

const Dropzone = ({ onChange }: DropzoneProps) => {
  const classes = useStyles()
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: ['.csv'],
    onDrop: async files => {
      if (!files) return
      const file = files?.[0]
      const reader = new FileReader()
      reader.onload = (e: any) => {
        const contents = e?.target?.result
        const parsed = PapaParse?.parse(contents, {
          header: true,
        })
        onChange(parsed)
      }
      reader?.readAsText?.(file)
    },
  })

  return (
    <div
      style={{
        width: 'calc(100% - 40px)',
        margin: '20px auto',
        maxWidth: '600px',
      }}>
      <Card {...getRootProps({ className: classes.root })}>
        <input {...getInputProps()} />
        <p>Drag & drop some files here, or click to select files</p>
      </Card>
      <aside>
        <h4>Files</h4>
        <ul>
          {acceptedFiles.map(file => (
            <li key={file.name}>
              {file.name} - {file.size} bytes
            </li>
          ))}
        </ul>
      </aside>
    </div>
  )
}

export default FileStep
