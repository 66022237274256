import { Typography } from '@material-ui/core'

import { useAuth } from '../../../hooks/useAuth'

const Home: React.FC = () => {
  const { user } = useAuth()

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}>
      <Typography> Welcome {user?.email}</Typography>
      {!user?.verified && (
        <Typography style={{ color: 'red' }}>
          You are not verified. Please look in your inbox.
        </Typography>
      )}
    </div>
  )
}

export default Home
