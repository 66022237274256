import { Route, Routes } from 'react-router-dom'

import { ProtectedMenu, PublicMenu } from '../utils/menus'
import RequireAuth from '../utils/RequireAuth'
import Layout from './Layout'

const Main: React.FC = () => {
  return (
    <div>
      <Routes>
        {PublicMenu?.map((item, i) => (
          <Route key={i} path={item.to} element={item.element} />
        ))}
        <Route element={<Layout />}>
          {ProtectedMenu?.map((item, i) => (
            <Route
              key={i}
              path={item.to}
              element={<RequireAuth>{item.element}</RequireAuth>}
            />
          ))}
        </Route>
      </Routes>
    </div>
  )
}

export default Main
