import './App.css'

import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client'
import { purple, red } from '@material-ui/core/colors'
import CssBaseline from '@material-ui/core/CssBaseline'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

import Main from './components/index'
import AuthProvider from './contexts/Auth'

const App: React.FC = () => {
  const darkTheme = createMuiTheme({
    palette: {
      type: 'dark',
      primary: {
        main: purple[300],
      },
      secondary: {
        main: red[300],
      },
    },
  })

  const link = createHttpLink({
    uri: process.env.REACT_APP_API_ENDPOINT,
    credentials: 'include',
  })

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link,
  })

  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <ThemeProvider theme={darkTheme}>
          <CssBaseline />
          <Main />
        </ThemeProvider>
      </AuthProvider>
    </ApolloProvider>
  )
}

export default App
