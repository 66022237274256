import { MenuItem, Select } from '@material-ui/core'
import { ControllerRenderProps } from 'react-hook-form'

import { TransactionType } from '../../generated/graphql'

const TypeInput: React.FC<ControllerRenderProps> = ({
  onChange,
  value,
}: ControllerRenderProps) => {
  return (
    <Select value={value} onChange={onChange}>
      {Object.keys(TransactionType)?.map((type, i) => (
        <MenuItem key={i + 1} value={type}>
          {type}
        </MenuItem>
      ))}
    </Select>
  )
}

export default TypeInput
