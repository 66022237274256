import { Button, TextField, Typography } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { useForgotPasswordMutation } from '../../../generated/graphql'

const ForgotPassword = (): JSX.Element => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm()
  const [forgotPassword, { loading, called }] = useForgotPasswordMutation()

  const onSubmit = async (values: { email: string }) => {
    await forgotPassword({ variables: { email: values.email } })
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <div style={{ width: '400px' }}>
        {!loading && !called && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              name="email"
              placeholder="Email"
              inputRef={register}
              fullWidth
              style={{ marginBottom: '20px' }}
            />
            {errors.email && <p>{errors.email.message}</p>}

            <Button variant="outlined" color="primary" type="submit">
              Send
            </Button>
            <Button
              style={{ marginLeft: '20px' }}
              color="secondary"
              type="button">
              <Link style={{ textDecoration: 'none' }} to="/login">
                <Typography color="textPrimary">Login</Typography>
              </Link>
            </Button>
          </form>
        )}
        {loading && <Typography>Loading...</Typography>}
        {!loading && called && (
          <Typography>
            An email has been sent if we have it registered. Please check your
            inbox
          </Typography>
        )}
      </div>
    </div>
  )
}

export default ForgotPassword
