import { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'

type ChartProps = {
  expenses: any
}

const CategorySummaryChart = ({ expenses }: ChartProps): JSX.Element => {
  const [dataset, setDataset] = useState<any>()
  const [category, setCategory] = useState<string>('Parents')

  useEffect(() => {
    if (expenses) {
      // [{  x, y }]
      if (category === 'Parents') {
        const parentDataset = expenses?.map((parent: any) => {
          const totalParent = parent?.children?.reduce(
            (childAcc: number, child: any) => {
              const months = child?.month?.reduce(
                (monthAcc: number, month: number) => monthAcc + month,
                0,
              )
              return childAcc + months
            },
            0,
          )
          return { x: parent?.categoryName, y: totalParent }
        })
        setDataset(parentDataset)
      } else {
        const parent = expenses?.find(
          (parentCat: any) => parentCat?.categoryName === category,
        )

        const childDataset = parent?.children?.map((child: any) => {
          const months = child?.month?.reduce(
            (monthAcc: number, month: number) => monthAcc + month,
            0,
          )

          return { x: child?.categoryName, y: months }
        })
        setDataset(childDataset)
      }
    }
  }, [expenses, category])

  const handleCategoryUpdate: (newCategory: string) => void = (
    newCategory: string,
  ) => {
    const isParent = expenses?.some(
      (parent: any) => parent?.categoryName === newCategory,
    )

    if (isParent) {
      setCategory(newCategory)
    } else {
      setCategory('Parents')
    }
  }

  return (
    <div style={{ padding: '40px', width: '100%' }}>
      <Bar
        data={{
          labels: dataset?.map((set: any) => set.x),
          datasets: [
            {
              label: 'This Month',
              data: dataset || [],
              backgroundColor: 'rgba(0, 255, 152, 0.2)',
              borderColor: 'rgba(0, 255, 152, 1)',
              borderWidth: 1,
            },
          ],
        }}
        options={{
          onClick: (e: any, el: any) => {
            const newCategory = el?.[0]?.element?.$context?.raw?.x
            if (newCategory) {
              handleCategoryUpdate(newCategory)
            }
          },
          scales: {
            x: {
              ticks: {
                color: '#fff',
              },
              grid: {
                display: false,
              },
            },
            y: {
              ticks: {
                color: '#fff',
              },
              grid: {
                display: false,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
      />
    </div>
  )
}

export default CategorySummaryChart
