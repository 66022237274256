import { Button } from '@material-ui/core'
import { GridCellParams } from '@material-ui/data-grid'

const RenderActions: React.FC<{
  params: GridCellParams
  remove: (index?: number | number[] | undefined) => void
}> = ({
  params,
  remove,
}: {
  params: GridCellParams
  remove: (index?: number | number[] | undefined) => void
}) => {
  const { rowIndex } = params
  return (
    <Button
      // variant="contained"
      style={{ width: '80px' }}
      color="secondary"
      onClick={() => remove(rowIndex)}>
      Remove
    </Button>
  )
}

export default RenderActions
