import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useEffect, useState } from 'react'
import { ControllerRenderProps } from 'react-hook-form'

import { useCategoriesQuery } from '../../generated/graphql'
import { useAuth } from '../../hooks/useAuth'
import { CascaderOptions } from './../pages/importer/index.d'

const CascadingSelector: React.FC<ControllerRenderProps> = ({
  value,
  onChange,
}: ControllerRenderProps) => {
  const [groupedCategories, setGroupedCategories] = useState<
    CascaderOptions[]
  >()

  const [curr, setCurr] = useState<CascaderOptions | null>(null)

  const { user } = useAuth()
  if (!user) return null

  const { data: { categories } = {}, loading } = useCategoriesQuery({
    variables: {
      userId: user.id,
    },
  })

  useEffect(() => {
    const _groupedCategories = categories?.reduce<CascaderOptions[]>(
      (children, category, i, origin) => {
        if (category?.parent) {
          children?.push({
            label: category?.name || '',
            value: category?.id || '',
            parent:
              origin?.find(parent => parent?.id === category?.parent?.id)
                ?.name || '',
          })
        }
        return children
      },
      [],
    )

    const _currVal = _groupedCategories?.find(cat => cat.value === value?.value)
    if (_currVal) {
      setCurr(_currVal)
    }

    setGroupedCategories(_groupedCategories)
  }, [categories])

  if (loading && !groupedCategories) return <p>Loading...</p>

  return (
    <Autocomplete
      options={groupedCategories || []}
      getOptionLabel={option =>
        option ? `${option?.label} - (${option?.parent})` : ''
      }
      style={{ width: '100%' }}
      onChange={(_, value) => {
        setCurr(value)
        onChange(value)
      }}
      value={curr}
      renderInput={params => {
        return (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <TextField {...params} fullWidth style={{ height: '100%' }} />
          </div>
        )
      }}
    />
  )
}

export default CascadingSelector
