import { Button } from '@material-ui/core'
import { DataGrid, GridCellParams, GridColDef } from '@material-ui/data-grid'
import { useFieldArray, useForm } from 'react-hook-form'

import {
  ColumnTypes,
  Query,
  TransactionType,
  useCreateDatarowsMutation,
} from '../../../../../generated/graphql'
import { useAuth } from '../../../../../hooks/useAuth'
import { ActionTypes, StepProps, TableStepInputs } from '../../index.d'
import RenderActions from './RenderActions'
import RenderCell from './RenderCell'

interface TableFormProps extends StepProps {
  datarows: Query['datarows']
}

const TableForm: React.FC<TableFormProps> = ({
  handleNext,
  formValues,
  setFormValues,
  datarows,
}: TableFormProps) => {
  const { user } = useAuth()
  if (!user) return null
  const { handleSubmit, control } = useForm<TableStepInputs>({
    defaultValues: {
      rows: formValues?.file?.data?.map(_row => {
        const identifierKey = formValues?.maps?.find(
          _map => _map?.newKey === ColumnTypes.identifier,
        )

        const amountOriginKey = formValues?.maps?.find(
          _map => _map?.newKey === ColumnTypes.amount,
        )?.originKey

        const amountValue = amountOriginKey
          ? parseFloat(
              _row[amountOriginKey]?.replace(',', '.')?.replace('−', '-'),
            )
          : 0

        let matchingIdentifier: any
        if (identifierKey) {
          matchingIdentifier =
            datarows?.find(fetchedRow => {
              if (fetchedRow?.identifier === _row?.[identifierKey?.originKey]) {
                if (fetchedRow?.transactionType === TransactionType.transfer) {
                  return fetchedRow?.amount === amountValue
                } else {
                  return true
                }
              }

              return false
            }) || {}
        }

        return {
          ...Object.keys(ColumnTypes)?.reduce<any>((obj, key) => {
            const findMap = formValues?.maps?.find(_map => _map?.newKey === key)

            let value
            if (findMap?.newKey === ColumnTypes?.amount) {
              value = amountValue
            } else if (findMap?.newKey === ColumnTypes?.transactionDate) {
              value = new Date(_row?.[findMap?.originKey])
            } else if (key === ColumnTypes.transactionType) {
              if (matchingIdentifier?.transactionType) {
                value = matchingIdentifier?.transactionType
              } else {
                value =
                  amountValue < 0
                    ? TransactionType.expense
                    : TransactionType.income
              }
            } else if (key === ColumnTypes.category) {
              value = { value: matchingIdentifier?.[key]?.id || '' }
            } else if (key === ColumnTypes.isUnusual) {
              value = false
            } else {
              value = findMap
                ? _row?.[findMap?.originKey]
                : matchingIdentifier?.[key]
                ? matchingIdentifier?.[key]
                : ''
            }

            obj[key] = value

            return obj
          }, {}),
        }
      }),
    },
  })

  const [createDatarows] = useCreateDatarowsMutation()

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'rows',
  })

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70 },
    ...Object?.keys?.(ColumnTypes)?.map(_colType => ({
      field: _colType,
      headerName: _colType,
      width: 170,
      renderCell: (params: GridCellParams) => (
        <RenderCell params={params} control={control} />
      ),
    })),
    {
      field: 'actions',
      headerName: 'Actions',
      width: 130,
      renderCell: (params: GridCellParams) => (
        <RenderActions params={params} remove={remove} />
      ),
    },
  ]

  const onSubmit = async (data: TableStepInputs) => {
    await createDatarows({
      variables: {
        userId: user.id,
        data: data?.rows?.map(row => ({
          ...row,
          category: row?.category?.value,
        })),
      },
    })
    setFormValues({ type: ActionTypes.Rows, payload: data?.rows })
    handleNext()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div style={{ padding: '20px' }}>
        <DataGrid
          rows={fields || []}
          columns={columns}
          checkboxSelection
          autoHeight
          disableSelectionOnClick
        />
      </div>
      <div
        style={{
          width: 'calc(100% - 40px )',
          maxWidth: '600px',
          margin: '0 auto 20px auto',
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() =>
            append(
              Object.keys(ColumnTypes)?.reduce(
                (obj, key) => ({ ...obj, [key]: '' }),
                {},
              ),
            )
          }>
          Add Row
        </Button>
        <Button variant="outlined" color="primary" type="submit">
          Next
        </Button>
      </div>
    </form>
  )
}

export default TableForm
