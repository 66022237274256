import {
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { useEffect, useState } from 'react'

const AccountTable = ({ data }: { data: any }): JSX.Element => {
  const [stateData, setStateData] = useState<any>()
  const headers = ['Account Name', 'Balance', '+ / - in SEK', '+ / - %']

  useEffect(() => {
    if (data) {
      setStateData(data)
    }
  }, [data])

  return (
    <div
      style={{
        width: 'calc(50% - 24px)',
        marginRight: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Card
        style={{
          width: '100%',
        }}>
        <CardContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {headers?.map((header, i) => (
                    <TableCell key={i} align={i === 0 ? 'left' : 'right'}>
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {stateData?.map((row: any, i: number) => {
                  const percent =
                    Math.round(
                      ((row?.startingBalance + row?.month) /
                        (row?.startingBalance > 0
                          ? row?.startingBalance
                          : row?.month / 2)) *
                        100,
                    ) - 100

                  return (
                    <TableRow key={i}>
                      <TableCell>{row?.name}</TableCell>
                      <TableCell align="right">
                        {row?.startingBalance?.toFixed(2)} kr
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{
                          color:
                            row?.month > 0
                              ? '#b6d7a7'
                              : row?.month < 0
                              ? '#ea9999'
                              : '#fff',
                        }}>
                        {row?.month?.toFixed(2)} kr
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{
                          color:
                            percent > 0
                              ? '#b6d7a7'
                              : percent < 0
                              ? '#ea9999'
                              : '#fff',
                        }}>
                        {percent}%
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </div>
  )
}

export default AccountTable
