import { useApolloClient } from '@apollo/client'
import { createContext } from 'react'

import {
  FieldError,
  IsLoggedInDocument,
  IsLoggedInQuery,
  useCreateUserMutation,
  useIsLoggedInQuery,
  useLoginMutation,
  useLogoutMutation,
  User,
} from '../generated/graphql'

export type LoginProps = {
  email: string
  password: string
}

export type ErrorProps = {
  name: string
  message: string
}

export interface AuthContextType {
  user: User | null | undefined
  login: ({ email, password }: LoginProps) => Promise<ErrorProps[] | null>
  register: ({ email, password }: LoginProps) => Promise<ErrorProps[] | null>
  logout: () => void
}

export const formatErrors = (errors: FieldError[]): ErrorProps[] => {
  return errors.map(err => ({ name: err.field, message: err.message }))
}

export const AuthContext = createContext<AuthContextType>(null!)

const AuthProvider = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  const { data: { isLoggedIn } = {}, loading } = useIsLoggedInQuery()
  const [login] = useLoginMutation()
  const [logout] = useLogoutMutation()
  const [createUser] = useCreateUserMutation()

  const apolloClient = useApolloClient()

  const handleLogin = async ({ email, password }: LoginProps) => {
    const res = await login({
      variables: {
        email: email,
        password: password,
      },
      update: (cache, { data }) => {
        cache.writeQuery<IsLoggedInQuery>({
          query: IsLoggedInDocument,
          data: {
            __typename: 'Query',
            isLoggedIn: data?.login?.user,
          },
        })
      },
    })

    if (res.data?.login?.fieldError) {
      return formatErrors(res.data.login.fieldError)
    }

    return null
  }

  const handleRegister = async ({ email, password }: LoginProps) => {
    const res = await createUser({
      variables: {
        email: email,
        password: password,
      },
      update: (cache, { data }) => {
        cache.writeQuery<IsLoggedInQuery>({
          query: IsLoggedInDocument,
          data: {
            __typename: 'Query',
            isLoggedIn: data?.createUser?.user,
          },
        })
      },
    })

    if (res.data?.createUser?.fieldError) {
      return formatErrors(res.data.createUser.fieldError)
    }

    return null
  }

  const handleLogout = async () => {
    await logout()
    await apolloClient.resetStore()
  }

  if (loading) return <p>Loading</p>

  return (
    <AuthContext.Provider
      value={{
        user: isLoggedIn,
        login: handleLogin,
        register: handleRegister,
        logout: handleLogout,
      }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
