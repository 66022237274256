import DateFnsUtils from '@date-io/date-fns'
import { Button, Typography } from '@material-ui/core'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { format } from 'date-fns'
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form'

import { useMonthSummaryLazyQuery } from '../../../generated/graphql'
import { useAuth } from '../../../hooks/useAuth'
import Boolean from '../../Inputs/Boolean'
import AbsAmountTransactionType from './AbsAmountTransactionType'
import AccountTable from './AccountTable'
import CategorySummaryChart from './CategorySummaryChart'
import { Datarows } from './Datarows'
import OutInBalance from './OutInBalance'
import SavingsBox from './SavingsBox'

const MonthSummary: React.FC = () => {
  const { user } = useAuth()
  if (!user) return null
  const { handleSubmit, control } = useForm()

  const [
    summaryQuery,
    { data: { monthSummary = {} } = {}, error, loading },
  ] = useMonthSummaryLazyQuery()

  const onSubmit = async (data: any) => {
    summaryQuery({
      variables: {
        year: format(new Date(data.date), 'yyyy-MM-dd'),
        includeUnusual: data.includeUnusual || false,
        userId: user.id,
      },
    })
  }

  return (
    <div style={{ padding: '20px' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="date"
          control={control}
          render={({ onChange, value }) => {
            return (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk
                  variant="inline"
                  format="dd/MM/yy"
                  margin="normal"
                  id="date-picker-inline"
                  value={value}
                  onChange={onChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            )
          }}
        />
        <Controller
          name="includeUnusual"
          control={control}
          render={(props: ControllerRenderProps) => <Boolean {...props} />}
        />
        {error && (
          <Typography style={{ color: 'red' }}>
            {error.message} - You have to verify your account
          </Typography>
        )}
        <Button
          disabled={loading}
          variant="outlined"
          color="primary"
          type="submit">
          Generate Report
        </Button>
      </form>
      <div style={{ padding: '48px', display: 'flex', flexWrap: 'wrap' }}>
        {monthSummary && <OutInBalance data={monthSummary?.assets} />}
        {monthSummary && <SavingsBox data={monthSummary?.assets} />}
        {monthSummary && <AccountTable data={monthSummary?.assets} />}
        {monthSummary && <AbsAmountTransactionType data={monthSummary} />}
        {monthSummary && (
          <CategorySummaryChart expenses={monthSummary?.expenses} />
        )}
        <div style={{ width: '100%' }}>
          {monthSummary && (
            <Datarows
              data={monthSummary?.datarows?.filter(
                row => row?.transactionType === 'expense',
              )}
              type="Expenses"
            />
          )}
          {monthSummary && (
            <Datarows
              data={monthSummary?.datarows?.filter(
                row => row?.transactionType === 'transfer',
              )}
              type="Transfers"
            />
          )}
          {monthSummary && (
            <Datarows
              data={monthSummary?.datarows?.filter(
                row => row?.transactionType === 'income',
              )}
              type="Income"
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default MonthSummary
