import {
  Button,
  createStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { Link, Outlet } from 'react-router-dom'

import { useAuth } from '../../hooks/useAuth'
import { ProtectedMenu } from '../../utils/menus'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100vh',
      display: 'flex',
      overflow: 'hidden',
    },
    sidebar: {
      width: '100%',
      height: '100%',
      maxWidth: 275,
      backgroundColor: theme.palette.background.paper,
    },
    content: {
      width: '100%',
      overflowY: 'scroll',
    },
  }),
)

const Layout: React.FC = () => {
  const classes = useStyles()

  const { logout } = useAuth()

  return (
    <div className={classes.root}>
      <div className={classes.sidebar}>
        <List component="nav">
          {ProtectedMenu.filter(({ hideInMenu = false }) => !hideInMenu)?.map(
            (item, i) => (
              <ListItem key={i} button component="a">
                <ListItemIcon>{item?.icon}</ListItemIcon>
                <ListItemText>
                  <Link style={{ textDecoration: 'none' }} to={item?.to}>
                    <Typography color="textPrimary">{item?.text}</Typography>
                  </Link>
                </ListItemText>
              </ListItem>
            ),
          )}
          <ListItem button component="a">
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText>
              <Button onClick={logout}>Logout</Button>
            </ListItemText>
          </ListItem>
        </List>
      </div>

      <div className={classes.content}>
        <Outlet />
      </div>
    </div>
  )
}

export default Layout
