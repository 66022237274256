import DateFnsUtils from '@date-io/date-fns'
import { Button, Typography } from '@material-ui/core'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { format } from 'date-fns'
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form'

import { useSummaryLazyQuery } from '../../../generated/graphql'
import { useAuth } from '../../../hooks/useAuth'
import Boolean from '../../Inputs/Boolean'
import AccountAccordion from './AccountAccordion'
import CategorySummaryChart from './CategorySummaryChart'
import ExpensesAccordion from './ExpensesAccordion'
import SummaryAccordion from './SummaryAccordion'
import SummaryLine from './SummaryLine'

const NewSummary: React.FC = () => {
  const { user } = useAuth()
  if (!user) return null

  const { handleSubmit, control } = useForm()

  const [
    summaryQuery,
    { data: { summary = {} } = {}, error, loading },
  ] = useSummaryLazyQuery()

  const onSubmit = async (data: any) => {
    summaryQuery({
      variables: {
        year: format(new Date(data.date), 'yyyy-MM-dd'),
        includeUnusual: data.includeUnusual || false,
        userId: user.id,
      },
    })
  }

  return (
    <div style={{ padding: '20px' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="date"
          control={control}
          render={({ onChange, value }) => {
            return (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk
                  variant="inline"
                  format="dd/MM/yy"
                  margin="normal"
                  id="date-picker-inline"
                  value={value}
                  onChange={onChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            )
          }}
        />
        <Controller
          name="includeUnusual"
          control={control}
          render={(props: ControllerRenderProps) => <Boolean {...props} />}
        />
        {error && (
          <Typography style={{ color: 'red' }}>
            {error.message} - You have to verify your account
          </Typography>
        )}
        <Button
          disabled={loading}
          variant="outlined"
          color="primary"
          type="submit">
          Generate Report
        </Button>
      </form>
      {summary ? <SummaryLine summary={summary} /> : null}
      <div style={{ padding: '40px' }}>
        {summary ? <SummaryAccordion summary={summary} /> : null}
        {summary ? (
          <ExpensesAccordion data={summary?.expenses} title="Expenses" />
        ) : null}
        {summary ? (
          <ExpensesAccordion data={summary?.income} title="Income" />
        ) : null}
        {summary ? (
          <ExpensesAccordion data={summary?.transfers} title="Transfers" />
        ) : null}
        {summary ? <AccountAccordion accounts={summary?.assets} /> : null}
      </div>
      {summary ? <CategorySummaryChart expenses={summary?.expenses} /> : null}
    </div>
  )
}

export default NewSummary
