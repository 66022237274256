import { Button, TextField, Typography } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { formatErrors } from '../../../contexts/Auth'
import {
  IsLoggedInDocument,
  IsLoggedInQuery,
  useChangePasswordMutation,
} from '../../../generated/graphql'

const ChangePassword = (): JSX.Element => {
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm()

  const { token } = useParams()
  const navigate = useNavigate()

  const [changePassword] = useChangePasswordMutation()

  const onSubmit = async (values: { newPassword: string; token: string }) => {
    const res = await changePassword({
      variables: {
        token: values.token,
        newPassword: values.newPassword,
      },
      update: (cache, { data }) => {
        cache.writeQuery<IsLoggedInQuery>({
          query: IsLoggedInDocument,
          data: {
            __typename: 'Query',
            isLoggedIn: data?.changePassword?.user,
          },
        })
      },
    })

    if (res.data?.changePassword?.fieldError) {
      formatErrors(res.data.changePassword.fieldError).forEach(error => {
        if (error) setError(error.name, { message: error.message })
      })
    } else if (res.data?.changePassword?.user) {
      navigate('/')
    }
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <div style={{ width: '400px' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            type="password"
            name="newPassword"
            placeholder="New Password"
            inputRef={register}
            style={{ marginBottom: '20px' }}
            fullWidth
          />
          {errors.newPassword && <p>{errors.newPassword.message}</p>}
          <input type="hidden" name="token" value={token} ref={register} />
          {errors.token && <p>{errors.token.message}</p>}

          <Button variant="outlined" color="primary" type="submit">
            Send
          </Button>
          <Button
            style={{ marginLeft: '20px' }}
            color="secondary"
            type="button">
            <Link style={{ textDecoration: 'none' }} to="/login">
              <Typography color="textPrimary">Login</Typography>
            </Link>
          </Button>
        </form>
      </div>
    </div>
  )
}

export default ChangePassword
