import { MenuItem, Select } from '@material-ui/core'
import { ControllerRenderProps } from 'react-hook-form'

import { useAccountsQuery } from '../../generated/graphql'
import { useAuth } from '../../hooks/useAuth'

const AccountSelector: React.FC<ControllerRenderProps> = ({
  value,
  onChange,
}: ControllerRenderProps) => {
  const { user } = useAuth()
  if (!user) return null

  const { data: { accounts } = {}, loading } = useAccountsQuery({
    variables: {
      userId: user.id,
    },
  })

  if (loading && !accounts) return <p>Loading...</p>

  return (
    <Select value={value} onChange={onChange}>
      <MenuItem value="">
        <em>None</em>
      </MenuItem>
      {accounts?.map((account, i) => {
        if (account?.id) {
          return (
            <MenuItem key={i + 1} value={account?.id}>
              {account?.name}
            </MenuItem>
          )
        } else {
          return null
        }
      })}
    </Select>
  )
}

export default AccountSelector
