import DateFnsUtils from '@date-io/date-fns'
import { Input, InputAdornment, TextField } from '@material-ui/core'
import { GridCellParams } from '@material-ui/data-grid'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { Control, Controller, ControllerRenderProps } from 'react-hook-form'

import { ColumnTypes } from '../../../../../generated/graphql'
import Boolean from '../../../../Inputs/Boolean'
import { TableStepInputs } from '../../index.d'
import AccountSelector from './../../../../Inputs/Accounts'
import CascadingSelector from './../../../../Inputs/Categories'
import TypeSelector from './../../../../Inputs/Type'

const RenderCell: React.FC<{
  params: GridCellParams
  control: Control<TableStepInputs>
}> = ({
  params,
  control,
}: {
  params: GridCellParams
  control: Control<TableStepInputs>
}) => {
  const { value, rowIndex, field } = params

  if (field === ColumnTypes.transactionDate) {
    return (
      <Controller
        name={`rows[${rowIndex}][${field}]`}
        control={control}
        defaultValue={value}
        render={({ onChange, value }) => {
          return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                autoOk
                variant="inline"
                format="dd/MM/yy"
                margin="normal"
                id="date-picker-inline"
                value={value}
                onChange={onChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          )
        }}
      />
    )
  }

  if (field === ColumnTypes.category) {
    return (
      <Controller
        name={`rows[${rowIndex}][${field}]`}
        control={control}
        defaultValue={value}
        render={(props: ControllerRenderProps) => (
          <CascadingSelector {...props} />
        )}
      />
    )
  }

  if (field === ColumnTypes.transactionType) {
    return (
      <Controller
        name={`rows[${rowIndex}][${field}]`}
        control={control}
        defaultValue={value}
        render={(props: ControllerRenderProps) => <TypeSelector {...props} />}
      />
    )
  }

  if (field === ColumnTypes.amount) {
    return (
      <Controller
        name={`rows[${rowIndex}][${field}]`}
        control={control}
        defaultValue={value}
        render={({ onChange, value }) => (
          <Input
            onChange={e => {
              onChange(parseFloat(e.target.value))
            }}
            value={value}
            type="number"
            endAdornment={<InputAdornment position="end">kr</InputAdornment>}
            fullWidth
          />
        )}
      />
    )
  }

  if (field === ColumnTypes.toAccount || field === ColumnTypes.fromAccount) {
    return (
      <Controller
        name={`rows[${rowIndex}][${field}]`}
        control={control}
        defaultValue={value}
        render={(props: ControllerRenderProps) => (
          <AccountSelector {...props} />
        )}
      />
    )
  }

  if (field === ColumnTypes.isUnusual) {
    return (
      <Controller
        name={`rows[${rowIndex}][${field}]`}
        control={control}
        defaultValue={value}
        render={(props: ControllerRenderProps) => <Boolean {...props} />}
      />
    )
  }

  return (
    <div style={{ display: 'flex' }}>
      <Controller
        name={`rows[${rowIndex}][${field}]`}
        control={control}
        defaultValue={value}
        render={(props: ControllerRenderProps) => {
          return <TextField {...props} fullWidth />
        }}
      />
    </div>
  )
}

export default RenderCell
