import { Button, TextField, Typography } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'

import { useAuth } from '../../../hooks/useAuth'

const Login = (): JSX.Element => {
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm()
  const navigate = useNavigate()

  const { login } = useAuth()

  const onSubmit = async (values: { email: string; password: string }) => {
    const errors = await login({
      email: values.email,
      password: values.password,
    })

    if (errors) {
      errors.forEach(error => {
        if (error) setError(error.name, { message: error.message })
      })
    } else {
      navigate('/')
    }
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <div style={{ width: '400px' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            name="email"
            placeholder="Email"
            inputRef={register}
            fullWidth
            style={{ marginBottom: '20px' }}
          />
          {errors.email && <p>{errors.email.message}</p>}
          <TextField
            type="password"
            name="password"
            placeholder="Password"
            inputRef={register}
            style={{ marginBottom: '20px' }}
            fullWidth
          />
          {errors.password && <p>{errors.password.message}</p>}
          <div style={{ width: '100%', textAlign: 'right' }}>
            <Link style={{ textDecoration: 'none' }} to="/forgot-password">
              <Typography color="textPrimary">Forgot password?</Typography>
            </Link>
          </div>
          <Button variant="outlined" color="primary" type="submit">
            Login
          </Button>
          <Button
            style={{ marginLeft: '20px' }}
            color="secondary"
            type="button">
            <Link style={{ textDecoration: 'none' }} to="/register">
              <Typography color="textPrimary">Register</Typography>
            </Link>
          </Button>
        </form>
      </div>
    </div>
  )
}

export default Login
