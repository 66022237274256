import {
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
// import { useEffect } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'

import {
  ColumnTypes,
  useCreateDatamapsMutation,
  // useDatamapsQuery,
} from '../../../../generated/graphql'
import { useAuth } from '../../../../hooks/useAuth'
import { ActionTypes, MappingStepInputs, StepProps } from './../index.d'

const MappingStep: React.FC<StepProps> = ({
  handleNext,
  formValues,
  setFormValues,
}: StepProps) => {
  const { user } = useAuth()
  if (!user) return null
  const {
    handleSubmit,
    control,
    // setValue, watch
  } = useForm<MappingStepInputs>({
    defaultValues: {
      mapping: formValues?.file?.meta?.fields?.map(field => ({
        originKey: field,
        newKey: undefined,
      })),
    },
  })

  // const { data: { datamaps } = {}, loading } = useDatamapsQuery({
  //   variables: {
  //     userId: user.id,
  //   },
  // })

  // useEffect(() => {
  //   if (datamaps) {
  //     if (datamaps?.length > 0) {
  //       const mappingVal = watch('mapping')
  //       console.log(mappingVal)
  //       const newMapping = mappingVal
  //         ?.map(map => {
  //           const findDatamap = datamaps?.find(
  //             oldMap => oldMap?.originKey === map?.originKey,
  //           )

  //           return { ...map, newKey: findDatamap?.newKey || '' }
  //         })
  //         ?.filter(map => map?.newKey)

  //       setValue('mapping', newMapping)
  //     }
  //   }
  // }, [datamaps])

  const [createDatamaps] = useCreateDatamapsMutation()

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'mapping',
  })

  const onSubmit = async (data: MappingStepInputs) => {
    await createDatamaps({
      variables: {
        userId: user.id,
        data: data?.mapping,
      },
    })

    setFormValues({ type: ActionTypes.Maps, payload: data?.mapping })
    handleNext()
  }

  // if (loading) return <p>Loading...</p>

  return (
    <Card
      style={{
        width: 'calc(100% - 40px)',
        maxWidth: '600px',
        margin: '20px auto ',
        padding: '50px 20px 20px 20px',
      }}>
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          {fields?.map((field, index) => {
            return (
              <div
                key={field?.id}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0 0 20px 0',
                }}>
                <Controller
                  name={`mapping[${index}].originKey`}
                  control={control}
                  rules={{ required: true }}
                  defaultValue={`${field.originKey}`}
                  render={({ onChange, value }) => {
                    return (
                      <FormControl
                        variant="outlined"
                        style={{ width: '42.5%' }}>
                        <InputLabel id="origin-key-label">
                          Origin Key
                        </InputLabel>
                        <Select
                          style={{
                            width: 'calc(100% - 10px)',
                            marginRight: '10px',
                          }}
                          labelId="origin-key-label"
                          // id="demo-simple-select-outlined"
                          value={value}
                          onChange={onChange}
                          label="Origin Key">
                          {formValues?.file?.meta?.fields?.map(field => {
                            return (
                              <MenuItem key={field} value={field}>
                                {field}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    )
                  }}
                />
                <Controller
                  name={`mapping[${index}].newKey`}
                  control={control}
                  rules={{ required: true }}
                  defaultValue={field?.newKey ? `${field.newKey}` : ''}
                  render={({ onChange, value }) => {
                    return (
                      <FormControl
                        variant="outlined"
                        style={{ width: '42.5%' }}>
                        <InputLabel id="new-key-label">New Key</InputLabel>
                        <Select
                          style={{
                            width: 'calc(100% - 10px)',
                            marginRight: '10px',
                          }}
                          labelId="new-key-label"
                          // id="demo-simple-select-outlined"
                          value={value}
                          onChange={onChange}
                          label="New Key">
                          {Object?.keys?.(ColumnTypes)?.map(key => {
                            return (
                              <MenuItem
                                key={key}
                                value={ColumnTypes[key as ColumnTypes]}>
                                {key}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    )
                  }}
                />
                <Button
                  // variant="contained"
                  style={{ width: '15%' }}
                  color="secondary"
                  onClick={() => remove(index)}>
                  Remove
                </Button>
              </div>
            )
          })}
          <div
            onClick={() =>
              append({ originKey: '', newKey: '', disabled: false })
            }
            style={{
              width: '100%',
              height: '28px',
              margin: '20px auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '4px',
              borderColor: 'lightgrey',
              borderStyle: 'dashed',
              borderWidth: '1px',
            }}>
            <span>Add mapping</span>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}>
            <Button variant="outlined" color="primary" type="submit">
              Next
            </Button>
          </div>
        </form>
      </CardContent>
    </Card>
  )
}

export default MappingStep
