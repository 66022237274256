import { Step, StepLabel, Stepper } from '@material-ui/core'
import { useEffect, useReducer, useState } from 'react'

import { ActionTypes, ReducerFn, SubmittedData } from './index.d'
import { getStepComponent } from './steps'
// import { resultobj } from './steps/test_obj'

const steps = ['Import', 'Map columns', 'Edit values', 'Finish']

const reducer: ReducerFn = (state, action) => {
  switch (action.type) {
    case ActionTypes.File:
      return { ...state, file: action.payload }
    case ActionTypes.Maps:
      return { ...state, maps: action.payload }
    case ActionTypes.Rows:
      return { ...state, rows: action.payload }
    case ActionTypes.Reset:
      return { ...action.payload }
    default:
      throw new Error()
  }
}

const initialState: SubmittedData = {
  file: null,
  maps: null,
  rows: null,
}

const Importer: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0)
  const [formValues, setFormValues] = useReducer(reducer, initialState)

  useEffect(() => {
    return () =>
      setFormValues({ type: ActionTypes.Reset, payload: initialState })
  }, [])

  const handleNext = () => {
    setActiveStep(prev => prev + 1)
  }
  return (
    <div>
      <Stepper alternativeLabel={true} activeStep={activeStep}>
        {steps.map(label => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
      {getStepComponent({ activeStep, handleNext, formValues, setFormValues })}
    </div>
  )
}

export default Importer
