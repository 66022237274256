import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { useEffect, useState } from 'react'

import { labels } from '../newSummary/methods'

const MultiLevelRow: React.FC<{ row: any }> = ({ row }: { row: any }) => {
  const [open, setOpen] = useState(false)
  const [parentTotal, setParentTotal] = useState<number[]>()
  const headers = ['', ...labels, 'Total', 'Average']

  useEffect(() => {
    if (row) {
      const totalByMonth = labels?.map((monthKey: any) => {
        const totalChildren = row?.children?.reduce(
          (childAcc: number, child: any) => {
            if (!child?.months?.[monthKey]) return childAcc

            const totalChildMonth = child?.months?.[monthKey]?.reduce(
              (nrAcc: number, nr: number) => nrAcc + nr,
              0,
            )

            return childAcc + totalChildMonth
          },
          0,
        )

        return totalChildren
      })

      const total = totalByMonth?.reduce(
        (acc: number, nr: number) => acc + nr,
        0,
      )
      const avg = total / totalByMonth?.length

      setParentTotal([...totalByMonth, total, avg])
    }
  }, [row])

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.categoryName}
        </TableCell>
        {parentTotal?.map((nr, i) => (
          <TableCell key={i}>{nr?.toFixed(2)} kr</TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={24}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {headers?.map((header, i) => (
                      <TableCell key={i}>{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row?.children?.map((child: any, i: number) => {
                    const totalByMonth = labels?.map((monthKey: string) => {
                      if (!child?.months?.[monthKey as any]) return 0
                      const childTotal = child?.months?.[
                        monthKey as any
                      ]?.reduce((acc: number, nr: number) => acc + nr, 0)

                      return childTotal
                    })

                    const total = totalByMonth?.reduce(
                      (acc: number, nr: number) => acc + nr,
                      0,
                    )
                    const avg = total / totalByMonth?.length
                    return (
                      <TableRow key={i}>
                        <TableCell>{child?.categoryName}</TableCell>
                        {totalByMonth?.map((month, j) => (
                          <TableCell key={j}>{month?.toFixed(2)} kr</TableCell>
                        ))}
                        <TableCell>{total?.toFixed(2)} kr</TableCell>
                        <TableCell>{avg?.toFixed(2)} kr</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default MultiLevelRow
